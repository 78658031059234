import React from "react";
import ServiceData from "./ServiceData";
import ServiceButton from "../Services/ServiceButton";
import "./Services.css";

const Services = () => {
  return (
    <>
      <section id="our-services">
        <div className="services_container">
          <h2 className="services_header">
            <span>
              <i className="fa-solid fa-angles-right"></i>
            </span>
            ΥΠΗΡΕΣΙΕΣ
          </h2>
          <div className="service_info_container">
            {ServiceData.map((service, index) => (
              <div
                className="service_info"
                key={index}
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <img
                  className="service_image_details"
                  src={service.img_src}
                  alt="dental images"
                />
                <h2 className="service__title">{service.title}</h2>
                <p className="service_detail_info">{service.description}</p>
                {/* Pass the specific URL as a prop to ServiceButton */}
                <ServiceButton url={service.url} />

              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
