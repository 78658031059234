import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethLefkansi from "../Pages/images/services_images/lefkansi.png"; // Import the image
import TeethLefkansi2 from "../Pages/images/services_images/lefkansi2.png"; // Import the image
import TeethLefkansi3 from "../Pages/images/services_images/lefkansi3.png"; // Import the image
import Contact from "../Pages/Contact";
import Services from "../Pages/Services";


const TeethWhiteningComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΛΕΥΚΑΝΣΗ</h2>
      {/* Main Image */}
      <img src={TeethLefkansi} alt="Teeth Whitening"  className="specific-image" />
      <p>
        Η λεύκανση είναι ο πιο απλός και συντηρητικός τρόπος να βελτιώσουμε το χαμόγελο μας! Είναι μια μέθοδος απόλυτα ασφαλής, βιοσυμβατή, εύκολη, οικονομική και μη επεμβατική τεχνική για να αποκτήσετε το λευκό χαμόγελο που επιθυμείτε.
      </p>
      <p>
        Σύμφωνα με την Αμερικάνικης Οδοντιατρικής Ομοσπονδίας τα υλικά λεύκανσης κατηγοριοποιούνται:
      </p>
      <ul>
        <li>Στα σκευάσματα που χρησιμοποιούνται αποκλειστικά στο οδοντιατρείο από εξειδικευμένο οδοντίατρο</li>
        <li>Στα υλικά, που παρέχονται από τον οδοντίατρο για χρήση στο σπίτι.</li>
      </ul>
      <p>
        Στο ιατρείο μας παρέχουμε 3 διαφορετικούς τρόπους λεύκανσης:
      </p>
      <ol>
        <li>Λεύκανση στο οδοντιατρείο με Λάμπα Λεύκανσης</li>
        <li>Λεύκανση στο σπίτι με εξατομικευμένους νάρθηκες και σύριγγες λεύκανσης</li>
        <li>Συνδυασμός των παραπάνω μεθόδων για μέγιστο αποτέλεσμα.</li>
      </ol>
      {/* Additional Images */}
      <div className="additional-images">
        <img src={TeethLefkansi2} alt="Additional"   className="specific-image"/>
        <img src={TeethLefkansi3} alt="Additional"  className="specific-image" />
      </div>
      {/* Additional Text */}
      <p>
        Η επιλογή της διαδικασίας της λεύκανσης γίνεται με εξατομικευμένο πρωτόκολλο με βάση τις ανάγκες του κάθε ασθενή. Λαμβάνονται ειδικές φωτογραφίες με χρωματολόγιο πριν και μετά το πέρας της λεύκανσης.
        Πρόκειται για γέλες (gel) με υπεροξείδιο του καρβαμιδίου ή υπεροξείδιο το υδρογόνου σε ποσοστά 10%, 16% στα προϊόντα που χρησιμοποιούνται στο σπίτι και σε ποσοστά περίπου 35% σε χρήση στο οδοντιατρείο.
        Στη λεύκανση στο σπίτι, η γέλη τοποθετείται στους εξατομικευμένους μαλακούς νάρθηκες, που κατασκευάζονται από ειδικό οδοντοτεχνικό εργαστήριο  και ταιριάζουν ακριβώς μόνο στα δικά σας δόντια. Ο ασθενής τοποθετεί στα δόντια του τους νάρθηκες με τη γελη, σύμφωνα με τις υποδείξεις της κυρίας Παναγιωτακη.
        Από κλινικές μελέτες προκύπτει ότι η λεύκανση είναι μια απόλυτα ασφαλής μέθοδος, εφόσον εφαρμοστεί σε υγιείς οδοντικούς ιστούς και ακολουθηθούν οι οδηγίες των λευκαντικών παραγόντων  και της οδοντιάτρου.
        Στην περίπτωση ευαισθησίας στα δόντια, δηλαδή αίσθημα ‘παγώματος’ σε περιοχές των δοντιών που υπάρχει αποκαλυμμένη ρίζα ή φθορά από έντονο βούρτσισμα, επικοινωνείτε με το ιατρείο και λαμβάνονται ειδικά μέτρα και τροποποιείται το πρωτόκολλο λεύκανσης.
        Η λεύκανση κρατάει για πάντα, εφόσον ο ασθενής ενταχθεί σε ένα πρόγραμμα διατήρησης του αποτελέσματος με εφαρμογή των ναρθήκων επαναληπτικής λεύκανσης κάθε 6 ή 12 μήνες, ανάλογα με τις καθημερινές συνήθειες του ασθενή,
      </p>
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethWhiteningComponent;
