import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesEmfitevmataComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Εμφυτεύματα</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="/dental-clinic/teeth-odontikaemfitefmata">Οδοντικά Εμφυτεύματα</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
              α εμφυτεύματα αποκαθιστούν τα ελλείποντα δόντια με αισθητικό και λειτουργικό τρόπο.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        
      
       
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesEmfitevmataComponent ;