import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethEpanelegxosComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΕΞΑΤΟΜΙΚΕΥΜΕΝΟ ΠΡΟΓΡΑΜΜΑ ΕΠΑΝΕΛΕΓΧΩΝ </h2>
      
      <p>
      Οι ανάγκες του κάθε ασθενή είναι διαφορετικές και καθορίζονται από παράγοντες όπως το επίπεδο στοματικής υγιεινής, το κάπνισμα, οι διατροφικές συνήθειες, οι ήδη υπάρχουσες βλάβες στα δόντια του η ύπαρξη ή όχι περιοδοντικής νόσου, ο ρυθμός σχηματισμού πέτρας. Αν κάποιος έχει πολλές τερηδόνες, στεφάνες εμφράξεις, απονευρώσεις και προσθετικές αποκαταστάσεις στο στόμα του, ανήκει σε ομάδα υψηλού κινδύνου και πρέπει να επισκέπτεται τον οδοντίατρο συχνότερα από κάποιον που έχει ελάχιστα προβλήματα στο στόμα του. Κατά την επίσκεψή σας στο ιατρείο μας μετά την αξιολόγηση των αναγκών σας, σας προτείνεται ένα εξατομικευμένο πρόγραμμα επανελέγχων που ανταποκρίνεται στις ανάγκες σας και μπορεί να μειώσει σημαντικά τα μελλοντικά προβλήματα και το κόστος οδοντιατρικής περίθαλψης! 
 
      </p>
      
      
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethEpanelegxosComponent ;
