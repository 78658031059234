import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesEksaktikiComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Εξακτική-Χειρουργική Στόματος </h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="/dental-clinic/teeth-eksagogidontiou">Εξαγωγή δοντιού</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
              Η εξαγωγή δοντιών είναι η έσχατη λύση του οδοντιάτρου, αφού πρώτα έχει εξαντλήσει όλες τις θεραπευτικές δυνατότητες για να σωθεί ένα δόντι.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"><Link to="/dental-clinic/teeth-eksagogifronimiti">Εξαγωγή φρονιμήτη</Link></Card.Title>
              <Card.Text className="card-text">
              Οι φρονιμίτες είναι τα τελευταία δόντια που ανατέλλουν στο στόμα. Όταν ανατέλλουν πλήρως και τα ούλα που τα περιβάλλουν είναι υγιή, τότε δεν χρειάζεται να αφαιρεθούν.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      
       
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesEksaktikiComponent ;