import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethTherapiaPedikwnComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΘΕΡΑΠΕΙΑ ΠΑΙΔΙΚΩΝ ΔΟΝΤΙΩΝ  </h2>
      
      <p>
      Αφήνοντας ένα νεογιλό δόντι να τερηδονιστεί δίχως οδοντιατρική φροντίδα συμβάλλουμε στην επιβάρυνση της γενικής υγείας του παιδιού (πονόδοντος, αποστήματα, αδυναμία σωστής μάσησης,) αλλά και της υγείας των μόνιμων δοντιών που θα τα διαδεχθούν.
Ο σκοπός των νεογιλών- παιδικών δοντιών είναι η διατήρηση του χώρου για την εμφάνιση του μόνιμου , η διευκόλυνση της μάσησης ,της ομιλίας αλλά και η διαμόρφωση σωστής σύγκλεισης . Με την καταστροφή των νεογιλών (τερηδονισμός , πρόωρη απώλεια) δημιουργούνται σοβαρά προβλήματα χώρου.

 
      </p>
      
      <p>

      Πολλοί γονείς δε γνωρίζουν ότι τα παιδικά νεογιλά δόντια πρέπει να τα φροντίζουν, γιατί:

      </p>

      <ul>
        <li>Με τα νεογιλά δόντια το παιδί τρώει για περίπου 10 χρόνια. Δεν πέφτουν όλα τα παιδικά δόντια στα 6 χρόνια της ζωής του παιδιού, τότε μόνον αρχίζουν να αλλάζουν τα μπροστινά παιδικά δόντια. Τα τελευταία πέφτουν στα 12 χρόνια του. </li>
        <li>Βοηθούν το παιδί στο να αναπτυχθεί σωστά η ομιλία του.</li>
        <li>Διατηρούν τον χώρο για την ομαλή διαδοχή των μόνιμων δοντιών</li>
        <li>Τα υγιή παιδικά δόντια -χωρίς αποστήματα και πόνους- συντελούν στη διατήρηση της γενικής υγείας του παιδιού.</li>
        
      </ul>

      <p>

      Άρα είναι επιτακτική η ανάγκη για ενημέρωση των γονιών όσον αφορά στη σωστή εφαρμογή στοματικής υγιεινής σε καθημερινή βάση στα παιδιά τους. Στην ενημέρωσή τους αυτή, εκτός του παιδιάτρου, ο οποίος θα συστήσει βούρτσισμα από το πρώτο κιόλας δοντάκι του παιδιού, πρωταρχικό ρόλο έχει ο οδοντίατρος.  
      </p>

     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethTherapiaPedikwnComponent ;
