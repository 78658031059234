import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethKatharismosComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΚΑΘΑΡΙΣΜΟΣ </h2>
      
      <p>
      Ο οδοντιατρικός καθαρισμός δοντιών είναι μια απλή διαδικασία που δεν συνοδεύεται από πόνο ή κάποια ενόχληση, κατά την οποία απομακρύνεται με ειδικά εργαλεία η οδοντική πέτρα, ώστε να προληφθούν ή να διαγνωστούν προβλήματα τερηδόνας, πλάκας και ουλίτιδας. Το αποτέλεσμα θα σας ανταμείψει, καθώς προσφέρει μια πολύ ευχάριστη αίσθηση δροσιάς, φρεσκάδας, καθαριότητας, λείας υφής των δοντιών και πιο λευκού χαμόγελου.
      </p>
      
      <p>

      Η διαδικασία είναι πολύ εύκολη. Αρχικά, ο οδοντίατρος εξετάζει τη γενική στοματική υγεία του ασθενούς και αυτό είναι σημαντικό, καθώς μπορεί να εντοπίσει τυχόν σημεία βλαβών και να εστιάσει περισσότερο σε αυτά κατά τον καθαρισμό. Ο έλεγχος αυτός γίνεται με τη χρήση ενός κατόπτρου (καθρέφτη), με το οποίο ελέγχει τα δόντια και τα ούλα για να εντοπίσει τυχόν σημεία φλεγμονής. Στη συνέχεια, ακολουθεί ο καθαρισμός με υπέρηχο, ο οποίος πετάει νερό με δόνηση και έτσι, απομακρύνονται η πέτρα και οι χρωστικές ουσίες.
      </p>
     
<p>
Σε κάποιες περιπτώσεις ασθενών, όπως σε παιδιά, ηλικιωμένους ή ασθενείς που το χρειάζονται μπορεί να εφαρμοστεί και φθορίωση.

Αναλογα με τις αναγκες του ο καθε ασθενεις, εντασσεται σε εξατομικευμενο προγραμμα καθαρισμων συνηθως καθε 6-8 μηνες.

</p>

      <Services/>
      <Contact />
    </div>
  );
};

export default TeethKatharismosComponent  ;
