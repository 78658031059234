import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethEmfraksisNeogilonComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>
      ΕΜΦΡΑΞΕΙΣ ΝΕΟΓΙΛΩΝ ΔΟΝΤΙΩΝ - ΠΟΛΦΟΤΟΜΗ
 </h2>
      
  <p>
  Τα νεογιλά δόντια φαίνεται ότι τερηδονίζονται συχνά, λόγω ελλειπους στοματικής υγιεινής και συχνής κατανάλωσης ζαχαρωδών ειδών. Άλλος ένας παράγοντας που παίζει καταλυτικό ρόλο στην πρόωρη εμφάνιση τερηδόνας φαίνεται να είναι η χρήση μη φθοριούχου οδοντόπαστας στο σπίτι. Η τερηδόνα στα νεογιλά δόντια εξελίσσεται με πιο γρήγορους ρυθμούς συγκριτικά με τα μόνιμα δόντια και γι αυτό είναι σύνηθες να προσβληθεί και ο πολφός (το νεύρο), οπότε το παιδί θα παρουσιάσει συμπτώματα οδονταλγίας η ακόμα και οιδήματος, αν δεν υπάρξει έγκαιρη διάγνωση και αντιμετώπιση του περιστατικού.

  </p>

  <p>

  Κατά την διαδικασία έμφραξης του δοντιού, αρχικά αφαιρείται η τερηδόνα και εν συνεχεία το δόντι σφραγίζεται μόνιμα με σύνθετη ρητίνη. Στην περίπτωση που έχει επεκταθεί η τερηδόνα και στον πολφό, τότε ακολουθείται μια διαδικασία που λέγεται πολφοτομη, όπου αφαιρείται ο μυλικος πολφός και διατηρείται μόνο ο ριζικός πολφός. Τοποθετείται ειδικό αιμοστατικό ζελέ προς αιμόσταση του ριζικού πολφού και αν αυτό επιτευχθεί τότε γεμίζουμε τον μυλικο θάλαμο με οξείδιο του ψευδάργυρου με ευγενολη και τοποθετούμε την τελική έμφραξη με ρητίνη. Στην περίπτωση που δεν επιτευχθεί αιμόσταση, τότε το νεογιλό δόντι οδηγείται προς εξαγωγή.

  </p>




      
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethEmfraksisNeogilonComponent ;