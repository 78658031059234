import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethMikrorogmes from "../Pages/images/services_images/mikroromes.png"; // Import the image
import Services from "../Pages/Services";

import Contact from "../Pages/Contact";


const TeethMikroRogmesComponent= () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΜΙΚΡΟΡΩΓΜΕΣ</h2>
      {/* Main Image */}
      <img src={TeethMikrorogmes} alt="Ενδοδοντική Θεραπεία"  className="specific-image" />
      <p>
      Πρόκειται για cracks που συμβαίνουν στην αδαμαντίνη, στην επιφάνεια δηλαδή των δοντιών, εξαιτίας κάποιας μετωπικής πρόσκρουσης με ένα αντικείμενο. Στο ιατρείο μας, γίνεται αξιολόγηση της μικρορωγμης, και στην περίπτωση που δεν χρειάζεται να επέμβουμε, απλώς την παρακολουθούμε σε κάθε σας επίσκεψη.
      </p>
      
     
    {/* Additional Text */}
  

      <Services/>
      <Contact />
    </div>
  );
};

export default TeethMikroRogmesComponent;
