import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethVrigmos from "../Pages/images/services_images/narthiskasvrigmou.png"; // Import the image
import Services from "../Pages/Services";


import Contact from "../Pages/Contact";


const TeethVrigmosComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΝΑΡΘΗΚΕΣ ΒΡΥΓΜΟΥ </h2>
      {/* Main Image */}
      <img src={TeethVrigmos } alt="Βρυγμός"  className="specific-image" />
      <p>
      Ο βρυγμός (ή βρουξισμός) είναι μια παραλειτουργική (μη φυσιολογική) συνήθεια η οποία χαρακτηρίζεται από το σφίξιμο ή το τρίξιμο των δοντιών που μπορεί να συμβαίνει τόσο ασυναίσθητα κατά τη διάρκεια του νυχτερινού ύπνου όσο και κατά τη διάρκεια της ημέρας.
Παρουσιάζεται σε ένα σημαντικό ποσοστό ενηλίκων ακόμα και σε παιδιά. Συχνά οι ασθενείς αναγνωρίζουν το πρόβλημα του βρυγμού είτε διότι το αντιλαμβάνονται την ώρα που σφίγγουν τα δόντια τους κατά τη διάρκεια της ημέρας είτε από το θόρυβο που κάνει το τρίξιμο των δοντιών τους κατά τη διάρκεια της νύχτας. Μεγάλο μέρος του πληθυσμού μπορεί να μην γνωρίζει ότι είναι βρουξιστές η να μην το παραδέχεται.
Άλλη μια ένδειξη του βρουξισμού είναι οι χαρακτηριστικές αποτριβές στις κοπτικές και μασητικές επιφάνειες των δοντιών, κάτι το οποίο το εντοπίζει ο οδοντίατρος κατά τη διάρκεια της κλινικής εξέτασης. Συνήθως οι βρουξιστές παρουσιάζουν αυτές τις χαρακτηριστικές αποτριβές αλλά αυτές δεν υπάρχουν πάντα. 



      </p>

      <p>

      Ο βρυγμός έχει πολυπαραγοντική αιτιολογία. 
Επειδή ο βρυγμός συμβαίνει συχνά κατά τη διάρκεια της νύχτας, έχει γίνει εκτενής μελέτη για την φυσιολογία του ύπνου. Οι πρόσφατες έρευνες δειχνουν ότι ο βρυγμός σχετίζεται με διαταραχές του ύπνου καθώς και με δυσκολίες αναπνοής κατά τη διάρκεια του ύπνου με πιο χαρακτηριστικό παράδειγμα την Αποφρακτική Άπνοια Ύπνου. Επιπλέον, υπάρχουν ισχυρές ενδείξεις ότι ο βρυγμός σχετίζεται με διαταραχές του κεντρικού νευρικού συστήματος.  
Πολλοί ψυχοκοινωνικοί παραγόντες που αφορούν τον τρόπο ζωής μας φαίνεται να αυξάνουν την πιθανότητα εμφάνισης βρυγμού. Άτομα με διαταραχές άγχους ή απλώς αγχώδη και έντονο τρόπο ζωής φαίνεται να διατρέχουν υψηλότερο κίνδυνο να παρουσιάσουν βρυγμό. Το στρες φαίνεται να προκαλεί κυρίως βρυγμό κατά τη διάρκεια της ημέρας και όχι νυχτερινό βρυγμό. Σε κατηγορία υψηλού κινδύνου ανήκουν ακόμα άτομα με κατάθλιψη, καπνιστές ή άτομα που κάνουν κατάχρηση ουσιών.


        
      </p>

      <p>
      Συνήθως οι βρουξιστές παρουσιάζουν κάποια χαρακτηριστικά συμπτώματα κάποια από τα οποία εξαφανίζονται όταν σταματήσει ο βρυγμός. Τα συμπτώματα αυτά δεν εμφανίζονται πάντα και όχι με την ίδια βαρύτητα σε όλους.


      </p>

      <p>
      Τα πιο χαρακτηριστικά από αυτά είναι τα εξής:

      </p>

      <ul>
        <li>Πονοκέφαλοι(κυρίως μετά το πρωινό ξύπνημα) </li>
        <li>Πόνος στην περιοχή του αυτιού (που δεν σχετίζεται με παθολογία στο αυτί όπως η ωτίτιδα)</li>
        <li>Μυικοί πόνοι ή πιάσιμο στην περιοχή του προσώπου, στους κροτάφους, στον αυχένα ή και στους ώμους</li>
        <li>Πόνος κατά τη μάσηση</li>
        <li>Δυσκολία διάνοιξης του στόματος</li>
        <li>Ενοχλήσεις ή και ήχοι στην περιοχή της κροταφογναθικής διάρθρωσης</li>
        <li>Ήχοι από το τρίξιμο των δοντιών κατά τη διάρκεια του ύπνου</li>
        <li>Χαρακτηριστικές αποτριβές στη μασητική και την κοπτική επιφάνεια των δοντιών</li>
        <li>Ρωγμές και κατάγματα σε δόντια, εμφράξεις και οδοντιατρικές εργασίες</li>
        <li>Ευαισθησία στα δόντια</li>
        <li>Εντυπώματα στα πλάγια της γλώσσας</li>
        
      </ul>

<p>

Η αντιμετώπιση του βρυγμού ξεκινάει με την αναγνώριση του προβλήματος. Ο βρυγμός που συμβαίνει κατά τη διάρκεια της ημέρας αντιμετωπίζεται κατά βάση με αλλαγή συμπεριφοράς. Ο ασθενής μαθαίνει να αντιλαμβάνεται τη στιγμή που σφίγγει τα δόντια του και τότε συνειδητά σταματάει το βρυγμό και φροντίζει να χαλαρώνει τη γνάθο του και να κρατά τα δόντια στην κανονική τους θέση και όχι σε επαφή μεταξύ τους.
Όσον αφορά τον νυχτερινό βρυγμό το σημαντικότερο κομμάτι της θεραπείας αφορά στην κατασκευή ενός κατάλληλου εξατομικευμένου νάρθηκα από σκληρό ακρυλικό τον οποίο ο ασθενής φοράει κατά τη διάρκεια της νύχτας. Ο νάρθηκας αυτός αν είναι κατάλληλα σχεδιασμένος βοηθάει στην προστασία των δοντιών και στη χαλάρωση των μασητήριων μυών που υπερλειτουργούν κατά τη διάρκεια του βρυγμού.


</p>


<p>

Χρειάζεται ιδιαίτερη προσοχή στην επιλογή ενός κατάλληλα κατασκευασμένου νάρθηκα βρυγμού. Ένας ακατάλληλος νάρθηκας όχι απλά είναι αναποτελεσματικός αλλά μπορεί να επιδεινώσει και τις εκδηλώσεις του βρυγμού.
Το βασικότερο στοιχείο που πρέπει να πληροί ένας νάρθηκας βρυγμού είναι να έχει κατασκευαστεί ειδικά για τον συγκεκριμένο ασθενή που θα τον χρησιμοποιήσει και να εφαρμόζει τέλεια στην οδοντοστοιχία του. Οι προκατασκευασμένοι νάρθηκες που κυκλοφορούν στο εμπόριο δεν εξασφαλίζουν σωστή εφαρμογή και υπάρχει ο κίνδυνος να φύγουν από τη θέση τους κατά τη διάρκεια του ύπνου.


</p>
    
     <Services/>
      <Contact />
      
    </div>
  );
};

export default TeethVrigmosComponent;
