import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethOpsiPorse from "../Pages/images/services_images/opsiporselanis.png"; // Import the image
import Services from "../Pages/Services";
import Contact from "../Pages/Contact";


const TeethOpsiPorselanisComponent= () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΟΨΕΙΣ ΠΟΡΣΕΛΑΝΗΣ</h2>
      {/* Main Image */}
      <img src={TeethOpsiPorse} alt="Teeth Whitening"   className="specific-image"/>
      <p>
      Οι όψεις πορσελάνης είναι αισθητικές αποκαταστάσεις μερικής κάλυψης που τοποθετούνται στις μπροστινές επιφάνειές των δοντιών και αποτελούν κορυφαίo υλικό σε ποιότητα και μακροβιότητα της αισθητικής αποκατάστασης.
      Οι όψεις πορσελάνης μπορούν:
      </p>
      
     
      
      <ul>
        <li>Στα σκευάσματα που χρησιμοποιούνται αποκλειστικά στο οδοντιατρείο από εξειδικευμένο οδοντίατρο</li>
        <li>Στα υλικά, που παρέχονται από τον οδοντίατρο για χρήση στο σπίτι.</li>
        <li>Στα υλικά, που παρέχονται από τον οδοντίατρο για χρήση στο σπίτι.</li>
        <li>Στα υλικά, που παρέχονται από τον οδοντίατρο για χρήση στο σπίτι.</li>
        <li>Στα υλικά, που παρέχονται από τον οδοντίατρο για χρήση στο σπίτι.</li>
        <li>Στα υλικά, που παρέχονται από τον οδοντίατρο για χρήση στο σπίτι.</li>
      </ul>

      
      {/* Additional Text */}
      <p>
      Στο ραντεβού της αποκατάστασης τα δόντια τροχίζονται καθόλου ή ελάχιστα. Ο ασθενής φεύγει με προσωρινές επικαλύψεις και κάποιες μέρες μετά έχει το νέο του χαμόγελο!
Μπορούν να εφαρμοστούν σε μεμονωμένο δόντι ή σε περισσότερα δόντια.

      </p>
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethOpsiPorselanisComponent;
