import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethFthoriosiComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΦΘΟΡΙΩΣΗ </h2>
      
      <p>
      Η φθορίωση συνίσταται συνήθως κάθε 6 μήνες ανάλογα τον τερηδονικό κίνδυνο του παιδιού και την διατροφή του ειδικότερα. Κατά τη φθορίωση γίνεται εφαρμογή στα δόντια ειδικών βερνικιών και gels που περιέχουν φθόριο. Το φθόριο είναι γνωστό για τις ισχυρές αντιτερηδονικές του ιδιότητες .Ο οδοντίατρος είναι εκείνος που θα κρίνει ποια μέθοδος είναι η ιδανικότερη για κάθε περιστατικό. Η ηλικία που πρέπει να ξεκινήσει η φθορίωση και η συχνότητα της είναι στοιχεία που θα αποφασιστούν από τον οδοντίατρο βλέποντας το κάθε παιδί ξεχωριστά.
 
      </p>
      
      <p>

      Η φθορίωση δεν αποτελεί μόνο προνόμιο των παιδιών, αλλά εφαρμόζεται και στους ενήλικες σε κάποιες περιπτώσεις ( ξηροστομία, πολλές νέες τερηδόνες , οδοντική υπερευαισθησία κ.ά).

      </p>
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethFthoriosiComponent ;
