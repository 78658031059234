// DentistOffice.js

import React from 'react';
import '../Pages/Iatrio.css'; // Import CSS file for styling
import photo1 from '../Pages/images/iatrio_images/photo1.png';
import photo2 from '../Pages/images/iatrio_images/photo2.jpg';
import photo3 from '../Pages/images/iatrio_images/photo3.jpg';
import photo4 from '../Pages/images/iatrio_images/photo4.jpg';
import photo5 from '../Pages/images/iatrio_images/photo5.jpg';
import photo6 from '../Pages/images/iatrio_images/photo6.png';


import Services from "../Pages/Services";



import Contact from "../Pages/Contact";

const Iatrio = () => {
  return (
    <div className="dentist-office">
      <div className="header">
        <h1 className='headiatrio'>Οδοντιατρείο Smile4ever</h1>
        <p className='paraiatrio'>
Καλώς ήλθατε στο οδοντιατρείο μας, ένας προορισμός όπου η φροντίδα των δοντιών σας συναντά την πρόοδο της τεχνολογίας. Εδώ, υιοθετούμε τις πιο σύγχρονες τεχνικές και χρησιμοποιούμε τα πιο προηγμένα μηχανήματα για να σας προσφέρουμε μια εμπειρία οδοντιατρικής που ξεπερνά τις προσδοκίες σας. Από την ψηφιακή απεικόνιση των δοντιών σας μέχρι τις εξειδικευμένες θεραπείες, οι νέες τεχνολογίες που εφαρμόζουμε διασφαλίζουν την υψηλότερη δυνατή ποιότητα και αποτελεσματικότητα. Επισκεφθείτε μας σήμερα και ανακαλύψτε πώς η συνάντηση της οδοντιατρικής με την καινοτομία μπορεί να αλλάξει τον τρόπο που βλέπετε τη φροντίδα των δοντιών σας.</p>
      </div>
      <div className="gallery">
        <div className="photo-wrapper">
          <img className="photo" src={photo1} alt="Dentist Office1" />
        </div>
        <div className="photo-wrapper">
          <img className="photo" src={photo2} alt="Dentist Office2" />
        </div>
        <div className="photo-wrapper">
          <img className="photo" src={photo3} alt="Dentist Office3 " />
        </div>
        <div className="photo-wrapper">
          <img className="photo" src={photo4} alt="Dentist Office3 " />
        </div>
        <div className="photo-wrapper">
          <img className="photo" src={photo5} alt="Dentist Office3 " />
        </div>
        <div className="photo-wrapper">
          <img className="photo" src={photo6} alt="Dentist Office3 " />
        </div>
      </div>
      <div className="services">
       
      </div>
      
<Services/>
<Contact/>

    </div>
  );
};

export default Iatrio;
