import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesPeriodontologiaComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Περιοδοντολογία</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="/dental-clinic/teeth-katharismos">Καθαρισμός</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
              Ο οδοντιατρικός καθαρισμός δοντιών είναι μια απλή διαδικασία που δεν συνοδεύεται από πόνο ή κάποια ενόχληση
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"><Link to="/dental-clinic/teeth-theoulitidas">Θεραπεία Ουλίτιδας</Link></Card.Title>
              <Card.Text className="card-text">
              Η ουλίτιδα είναι η φλεγμονή που αναπτύσσεται στα ούλα εξαιτίας της ανεπαρκούς απομάκρυνσης της οδοντικής πλάκας.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-mixeirourgiki">Μη Χειρουργική περιοδοντική Θεραπεία</Link></Card.Title>
              <Card.Text className="card-text">
              Πραγματοποιείται απομάκρυνση των υπερουλικών αλλά και των υποουλικών εναποθέσεων πλάκας και τρυγίας (πέτρας)
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-epanelegxos">Εξατομικευμένο Πρόγραμμα Επανελέγχων</Link></Card.Title>
              <Card.Text className="card-text">
              Οι ανάγκες του κάθε ασθενή είναι διαφορετικές και καθορίζονται από παράγοντες όπως το επίπεδο στοματικής υγιεινής, το κάπνισμα, οι διατροφικές συνήθειες
              </Card.Text>
            </Card.Body>
            
          </Card>
        </Col>
       
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesPeriodontologiaComponent ;