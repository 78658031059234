import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethEMeriki from "../Pages/images/services_images/meriki.png"; // Import the image
import Services from "../Pages/Services";

import Contact from "../Pages/Contact";


const TeethMerikiOdontostixiaComponent= () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΜΕΡΙΚΗ ΟΔΟΝΤΟΣΤΟΙΧΙΑ</h2>
      <p>
      Οι μερικές οδοντοστοιχίες είναι τα γνωστά σε όλους «μηχανάκια» ή «μασελάκια». Επιλέγονται από πολλούς ασθενείς λόγω του ότι αποτελούν μια οικονομική λύση και σχετικά γρήγορη και η επιδιόρθωση τους είναι γρήγορη και οικονομική. Με τις μερικές οδοντοστοιχίες βοηθάμε τους ασθενείς που έχουν χάσει κάποια από τα δόντια τους να τα αντικαταστήσουν. Αποτελούνται από μεταλλικό σκελετό, ακρυλική (ροζ) βάση και τεχνητά ακρυλικά δόντια.
Είναι κινητές εργασίες, δηλαδή δεν είναι μόνιμα κολλημένες στο στόμα, αλλά τοποθετούνται και αφαιρούνται αρκετές φορές κατά τη διάρκεια της μέρας.
Οι μερικές οδοντοστοιχίες επεκτείνονται και στις δύο πλευρές του στόματος ανεξάρτητα από το αν έχουν χαθεί δόντια μόνο δεξιά ή μόνο αριστερά. Συγκρατούνται σε κάποια από τα εναπομείναντα φυσικά δόντια (δόντια στηρίγματα) με μεταλλικά άγκιστρα ή με συνδέσμους ακριβείας.
Η επιτυχία της μερικής οδοντοστοιχίας βασίζεται στο σωστό σχεδιασμό που πρέπει να γίνεται από τον οδοντίατρο. 

      </p>

      {/* Main Image */}
      <img src={TeethEMeriki} alt="Ενδοδοντική Θεραπεία"  className="specific-image" />
     
      
     
    
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethMerikiOdontostixiaComponent;
