import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethEndodontiki from "../Pages/images/services_images/endodontiki.png"; // Import the image
import Services from "../Pages/Services";

import Contact from "../Pages/Contact";


const TeethEndodontikiComponent= () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΕΝΔΟΔΟΝΤΙΚΗ ΘΕΡΑΠΕΙΑ</h2>
      {/* Main Image */}
      <img src={TeethEndodontiki} alt="Ενδοδοντική Θεραπεία"   className="specific-image"/>
      <p>
      Η γνωστή σε όλους απονεύρωση είναι οδοντιατρική πράξη που διενεργείται στο ιατρείο προκειμένου να διατηρηθεί κάποιο δόντι στον οδοντικό φραγμό του ασθενούς και να αποφευχθεί η απώλειά του με εξαγωγή. Είναι μια διαδικασία που πραγματοποιείται όταν ο πολφός ενός δοντιού έχει μολυνθεί η έχει νεκρωθεί, λόγω τερηδόνας, κατάγματος η τραύματος.
Τα μικρόβια του στόματος εισβάλλουν στον πολφό, τον μολύνουν και τον καταστρέφουν και οι τοξίνες που παράγονται μπορούν να περάσουν από το άκρο της ρίζας έξω από το δόντι, στο οστό της γνάθου και να προκαλέσουν και εκεί φλεγμονή και απόστημα. 

      </p>
      
     
    {/* Additional Text */}
      <p>
      
Κατά την ενδοδοντική θεραπεία αφαιρούνται τα μικρόβια και τα μολυσμένα υπολείμματα του πολφού από το μυλικό θάλαμο και τους ριζικούς σωλήνες, καθαρίζεται το εσωτερικό του δοντιού και στη συνέχεια σφραγίζεται ερμητικά με ένα ειδικό υλικό. Έτσι αποτρέπεται η επιμόλυνση του δοντιού μελλοντικά ενώ από το απόστημα που πιθανώς υπάρχει στο οστό κάτω από το δόντι εξαφανίζεται. 
Στην περίπτωση που ένας ασθενής δεν υποβληθεί σε θεραπεία, η φλεγμονή θα επεκταθεί στο οστό κάτω από το δόντι, το οποίο καταστρέφεται, ο πόνος συνήθως χειροτερεύει και τελικά η μόνη λύση είναι η εξαγωγή του δοντιού. 


      </p>

<p>

Μετά το πέρας μιας ορθής ενδοδοντικής θεραπείας, το δόντι θα είναι ασυμπτωματικό, θα λειτουργεί και θα διατηρηθεί στον οδοντικό φραγμό όπως και τα υπόλοιπα ακέραια δόντια, αρκεί όμως να έχει γίνει μια σωστή έμφραξη του δοντιού και εν συνεχεία το δόντι να δεχθεί την κατάλληλη προσθετική αποκατάσταση (στεφάνη).

</p>

      <Services/>
      <Contact />
      

    </div>
  );
};

export default TeethEndodontikiComponent;
