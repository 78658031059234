import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethMetalokera from "../Pages/images/services_images/metalokera.png"; // Import the image
import Services from "../Pages/Services";


import Contact from "../Pages/Contact";


const TeethMetaloKeraComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΜΕΤΑΛΛΟΚΕΡΑΜΙΚΕΣ ΣΤΕΦΑΝΕΣ ΚΑΙ ΓΕΦΥΡΕΣ</h2>
      {/* Main Image */}
      <img src={TeethMetalokera } alt="Teeth Ritini"   className="specific-image"/>
      <p>
      Οι μεταλλοκεραμικές στεφάνες μπορούν να αποτελέσουν μια αξιόπιστη από άποψη αντοχής και μακροβιότητας, πιο οικονομική σε σχέση με τις ολοκεραμικές στεφάνες, αισθητική λύση. Είναι οι στεφάνες, που εξωτερικά έχουν το λευκό χρώμα της πορσελάνης και εσωτερικά μη ορατό μεταλλικό σκελετό.
Οι μεταλλοκεραμικές στεφάνες τοποθετούνται:

      </p>
      
      <ul>
        <li>σε δόντια, που δε μπορούν να αποκατασταθούν με απλές εμφράξεις</li>
        <li>σε δόντια με ενδοδοντική θεραπεία, που χρειάζονται αποκατάσταση και προστασία από τον κίνδυνο κατάγματος</li>
        <li>    
        <li>σε δόντια, που αισθητικά απαιτείται αποκατάσταση, αλλαγή σε χρώμα, θέση, και σχήμα του φυσικού δοντιού</li>
        <li>σε δόντια που χρησιμοποιούνται ως στηρίγματα σε γέφυρες ή οδοντοστοιχίες</li>
        </li>
        
      </ul>
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethMetaloKeraComponent;
