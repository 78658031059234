import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethEksagogiFronimitiComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>
      ΕΞΑΓΩΓΗ ΦΡΟΝΙΜΗΤΗ
 </h2>
      
  <p>
  Οι φρονιμίτες είναι τα τελευταία δόντια που ανατέλλουν στο στόμα. Όταν ανατέλλουν πλήρως και τα ούλα που τα περιβάλλουν είναι υγιή, τότε δεν χρειάζεται να αφαιρεθούν. Καθώς είναι τα τελευταία δόντια στο φραγμό, παρακολουθούνται, γιατί είναι πιο εύκολο να «χαλάσουν» ή να έχουν πρόβλημα στα ούλα, αφού δεν είναι εύκολο να γίνει αποτελεσματικό βούρτσισμα τόσο πίσω

  </p>

  <p>

  Όταν η ανατολή των φρονιμητων δεν είναι σωστή, τότε μπορεί να δημιουργηθούν προβλήματα. Μπορεί να κινηθούν πλάγια, να παραμείνουν μερικώς κάτω από τα ούλα ή ολικά έγκλειστα κάτω από τα ούλα ή και μέσα στο οστό της γνάθου. Τα έγκλειστα δόντια μπορεί να έχουν πολλές διαφορετικές θέσεις μέσα στο οστό στην προσπάθεια τους να ανατείλουν κανονικά.

  </p>

  <p>

  Οι φρονιμίτες λόγω της μη σωστής θέσης τους στην γνάθο μπορούν να προκαλέσουν πολλά προβλήματα. Όταν είναι ημιέγκλειστα (έχουν δηλαδή ανατείλει "εμφανιστεί" μερικώς μέσα στο στόμα), το άνοιγμα γύρω από το δόντι επιτρέπει σε βακτήρια να εισέλθουν ανάμεσα στα ούλα και το δόντι και να δημιουργήσουν φλεγμονή (περιστεφανίτιδα). Αποτέλεσμα της φλεγμονής είναι οίδημα (πρήξιμο), περιορισμός στην διάνοιξη του στόματος, πόνος και μερικές φορές πυρετός.


  </p>


  <p>
  Περιοδοντικά προβλήματα επίσης αυξάνουν με την πάροδο της ηλικίας όταν υπάρχουν και παραμένουν οι φρονιμίτες όπως επίσης και η τερηδόνα. Η αφαίρεση του εγκλείστου ή εγκλείστων φρονιμίτων συνήθως λύνει τα παραπάνω προβλήματα, ενώ η αφαίρεσή τους σε νεαρή ηλικία (μικρότερη των 25) ελαττώνει τις πιθανές επιπλοκές μετά την επέμβαση.
  </p>

    <p>
    Ο κλινικός έλεγχος και η ακτινογραφική εικόνα των φρονιμίτων μπορούν να εκτιμηθούν και να προβλεφθεί με ακρίβεια η πιθανότητα άμεσων ή μελλοντικών προβλημάτων.
Η έγκαιρη εκτίμηση και διάγνωση στην εφηβική ηλικία οδηγεί σε πιο αποτελεσματική αντιμετώπιση.

    </p>


<p>
Η εξαγωγή γίνεται με τοπική αναισθησία στο χώρο του ιατρείου και ο πόνος απουσιάζει κατά την επέμβαση. Οι μετεγχειρητικές οδηγίες για τις μέρες που θα ακολουθήσουν είναι αντίστοιχες με την απλή εξαγωγή δοντιού.

</p>


 
      

<p>
Για οποιοδήποτε πρόβλημα ή απορία επικοινωνήστε με το ιατρείο μας.
  
</p>


      
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethEksagogiFronimitiComponent ;