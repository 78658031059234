import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethVrigmosAthlitikos from "../Pages/images/services_images/athlitikosnarthikas.png"; // Import the image
import Services from "../Pages/Services";


import Contact from "../Pages/Contact";


const TeethAthlitikosNarthikasComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΑΘΛΗΤΙΚΟΙ ΝΑΡΘΗΚΕΣ  </h2>
      {/* Main Image */}
      <img src={TeethVrigmosAthlitikos } alt="Βρυγμός"  className="specific-image" />
      <p>
      Οι περισσότεροι τραυματισμοί στην περιοχή του προσώπου και των δοντιών μπορούν να προληφθούν. Στα μέσα πρόληψης για τη μείωση των οδοντικών και γναθοπροσωπικών τραυματισμών περιλαμβάνονται τα κράνη, οι προσωπίδες και οι αθλητικοί στοματικοί νάρθηκες.

Ο αθλητικός στοματικός νάρθηκας είναι κάθε ενδοστοματικό κινητό μηχάνημα το οποίο είναι κατασκευασμένο από ελαστικό υλικό με αντοχή στην παραμόρφωση, η χρήση του οποίου βοηθάει στον περιορισμό του τραυματισμού των δοντιών, της κροταφογναθικής άρθρωσης, των οστών της άνω και κάτω γνάθου και τέλος των ενδοστοματικών και περιστοματικών ιστών.



      </p>

      <p>
      Ο μηχανισμός δράσης των ναρθήκων συνίσταται στην απορρόφηση της ενέργειας και τη διασπορά της, ώστε οι συνέπειες του χτυπήματος να μην είναι κλινικά σημαντικές. Με αυτόν τον τρόπο αποφεύγονται τα τραύματα των μαλακών ιστών, τα κατάγματα της μύλης ή της ρίζας των δοντιών, η μερική ή ολική εκγόμφωση ή εμβύθιση των δοντιών, τα κατάγματα της κάτω γνάθου και η εξάρθρωση ή παραβίαση των ορίων λειτουργίας της κροταφογναθικής διάρθρωσης (ΚΓΔ) που μπορεί να οδηγήσει σε δυσλειτουργία της ΚΓΔ. Ο νάρθηκας παρεμβάλλεται μεταξύ των δοντιών και λόγω του πάχους του, κατά τη σύγκλειση των δοντιών απομακρύνει τον κόνδυλο της κάτω γνάθου από την κροταφική γλήνη. Έτσι, σε περίπτωση χτυπήματος στην κάτω γνάθο ο κόνδυλος εμποδίζεται να κτυπήσει βίαια στη βάση του κρανίου και να τραυματιστεί. Επίσης, λόγω της ελαστικότητάς του ο νάρθηκας μειώνει την ένταση του κτυπήματος προστατεύοντας τον εγκέφαλο από πιθανή διάσειση.


        
      </p>

      
    
     <Services/>
      <Contact />
      
    </div>
  );
};

export default TeethAthlitikosNarthikasComponent;
