import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Επανορθωτική και Αισθητική Οδοντιατρική</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="/dental-clinic/teeth-whitening">Λεύκανση</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
              Η λεύκανση είναι ο πιο απλός και συντηρητικός τρόπος να βελτιώσουμε το χαμόγελο μας! 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"><Link to="/dental-clinic/teeth-ritini">Εμφράξεις Σύνθετης Ρητίνης</Link></Card.Title>
              <Card.Text className="card-text">
              Στη σύγχρονη εποχή, το υλικό εκλογής για το λεγόμενο σφράγισμα είναι οι σύνθετες ρητίνες
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-opsisritinis">Όψεις Σύνθετης Ρητίνης</Link></Card.Title>
              <Card.Text className="card-text">
              Ολοένα και περισσότεροι ασθενείς και οδοντίατροι επιλέγουν τις όψεις ρητίνης όταν θέλουν να αλλάξουν το χρώμα, το σχήμα, το μέγεθος και τη θέση των δοντιών. 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-opsisporselanis">Όψεις Πορσελάνης</Link></Card.Title>
              <Card.Text className="card-text">
              Οι όψεις πορσελάνης είναι αισθητικές αποκαταστάσεις μερικής κάλυψης που τοποθετούνται στις μπροστινές επιφάνειές των δοντιών
              </Card.Text>
            </Card.Body>
            
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-opsisporselanis">Ολοκεραμικές Στεφανές και Γέφυρες</Link></Card.Title>
              <Card.Text className="card-text">
              Υπάρχουν στεφάνες χωρίς μεταλλικό όριο, λευκές εσωτερικά και εξωτερικά, που μιμούνται τα φυσικά σας δόντια 
              </Card.Text>
            </Card.Body>
            
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-metalokeramikes">Μεταλλοκεραμικές Στεφάνες και Γέφυρες</Link></Card.Title>
              <Card.Text className="card-text">
              Οι μεταλλοκεραμικές στεφάνες μπορούν να αποτελέσουν μια αξιόπιστη από άποψη αντοχής και μακροβιότητας
              </Card.Text>
            </Card.Body>
            
          </Card>
        </Col>
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesComponent;