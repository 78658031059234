import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethOdontikaEmfitemata from "../Pages/images/services_images/./odontikaemfitemata.png"; // Import the image
import Services from "../Pages/Services";
import Contact from "../Pages/Contact";

const BullettoNumbers = {
  listStyleType: 'decimal'
};

const TeethOdontikaEmfitefmataComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΟΔΟΝΤΙΚΑ ΕΜΦΥΤΕΥΜΑΤΑ</h2>
      {/* Main Image */}
      <img src={TeethOdontikaEmfitemata} alt="Οδοντικά Εμφυτεύματα"   className="specific-image"/>
      <p>
        Τα εμφυτεύματα αποκαθιστούν τα ελλείποντα δόντια με αισθητικό και λειτουργικό τρόπο. Ένας άνθρωπος που έχει χάσει τα δόντια του βάζοντας εμφυτεύματα αποκτά ξανά την δυνατότητα να τρώει όπως πριν, να χαμογελά με άνεση και αυτοπεποίθηση και να ανταποκρίνεται στις κοινωνικές του υποχρεώσεις γνωρίζοντας ότι τα δόντια του δείχνουν απόλυτα φυσικά, και ότι το κόκκαλο στις γνάθους του δεν θα απορροφηθεί και η όψη του προσώπου του δεν θα αλλάξει. Τα εμφυτεύματα μοιάζουν με βίδα και κατασκευάζονται από τιτάνιο, το οποίο είναι απόλυτα βιοσυμβατό με τον ανθρώπινο οργανισμό.
      </p>
      <p>
        Τοποθετούνται χειρουργικά στο οστό της γνάθου και είναι η βάση πάνω στην οποία «βιδώνουμε» το τεχνητό δόντι (στεφάνη). Η χειρουργική τοποθέτηση γίνεται από εξειδικευμένο γιατρό. Τοποθετούνται με τοπική αναισθησία και η διαδικασία δεν είναι καθόλου επίπονη. Με το πέρασμα περίπου 3 μηνών, οστικά κύτταρα δημιουργούνται γύρω από το εμφύτευμα, ενσωματώνοντάς το μέσα στο οστό της γνάθου, μια διαδικασία που ονομάζεται οστεοενσωμάτωση. Αφού ενσωματωθούν στις γνάθους, κατασκευάζεται και «βιδώνει» από πάνω η τελική αποκατάσταση (στεφάνη ή γέφυρα).
      </p>
      <h2> ΠΛΕΟΝΕΚΤΗΜΑΤΑ </h2>
      <p>
        Τα οδοντικά εμφυτεύματα είναι η καλύτερη επιλογή για ενήλικα άτομα κάθε ηλικίας που έχασαν ένα ή περισσότερα δόντια από τερηδόνα, από τραυματισμό ή ατύχημα ή εξαιτίας των επιπτώσεων της περιοδοντικής νόσου των δοντιών που προκαλεί την απώλειά τους. Το οδοντικό εμφύτευμα αποτελεί μια τεχνητή οδοντική ρίζα που τοποθετείται από εξειδικευμένο ιατρό στο οστό της γνάθου με σκοπό να συγκρατήσει στεφάνη, γέφυρα ή και πλήρη οδοντοστοιχία, σε περιπτώσεις απώλειας ενός ή πολλών δοντιών. Η αποκατάσταση με την τοποθέτηση οδοντικών εμφυτευμάτων αποτελεί ιδανική λύση για τους ασθενείς που έχουν χάσει ένα ή περισσότερα φυσικά δόντια και αντιμετωπίζουν αισθητικά και λειτουργικά προβλήματα στην καθημερινότητά τους.
      </p>

      <ul>
        <li style={BullettoNumbers}>Αποκαθίσταται πλήρως η μάσηση και η ομιλία με τη χρήση των οδοντικών εμφυτευμάτων, λειτουργίες που διαταράσσονται σημαντικά από την απώλεια δοντιών στην μπροστινή ή οπίσθια περιοχή του στόματος. Η άρθρωση και η ομιλία των ασθενών επανέρχονται μετά την τοποθέτηση εμφυτευμάτων και μιλούν όπως ακριβώς και με τα φυσικά τους δόντια. Ενισχύεται και η μασητική τους ικανότητα, με αποτέλεσμα να γίνεται καλύτερη η πέψη των τροφών.</li>
        <li style={BullettoNumbers}>Σε περιπτώσεις ύπαρξης κενών στην οδοντοστοιχία λόγω μερικής απώλειας δοντιών, είναι σημαντικό να εφαρμόζονται άμεσα θεραπείες αποκατάστασης για να αποτρέπονται φαινόμενα υπερέκφυσης ή μετατόπισης των φυσικών δοντιών.</li>
        <li style={BullettoNumbers}>Προσφέρουν άριστο αισθητικό αποτέλεσμα που επιδρά άμεσα στην αυτοπεποίθηση και την αυτοεκτίμηση των ασθενών. Τα οδοντικά εμφυτεύματα δεν γίνονται αντιληπτά όταν χαμογελούν, μιλούν ή τρώνε και δεν μπορεί κάποιος να διακρίνει οπτικές διαφορές ανάμεσα στα τεχνητά και τα φυσικά δόντια της στοματικής κοιλότητας. Η αίσθηση και η εμφάνιση των νέων δοντιών που στηρίζονται στα εμφυτεύματα προσομοιάζουν σε εκείνη των φυσικών και οι ίδιοι οι ασθενείς σχεδόν ξεχνούν την αρχική απώλεια.</li>
        <li style={BullettoNumbers}>Δεν παρασκευάζονται (τροχίζονται) τα διπλανά δόντια όπως στην περίπτωση της γέφυρας.</li>
        <li style={BullettoNumbers}>Προλαμβάνεται το γηρασμένο προσωπείο, καθώς σε μεγαλύτερης ηλικίας άτομα που έχουν απολέσει μεγάλο αριθμό των φυσικών δοντιών τους, τα εμφυτεύματα αποκαθιστούν την αισθητική του προσώπου και στηρίζουν τα χείλη και τα μάγουλα εσωτερικά, αποτρέποντας την εμφάνιση γηρασμένου προσωπείου.</li>
        <li style={BullettoNumbers}>Περιορίζεται σημαντικά η οστική απώλεια λόγω απορρόφησης και προφυλάσσονται τα φυσικά δόντια, ελαττώνοντας έτσι τις πιθανότητες για την μελλοντική ανάγκη μεγάλων προσθετικών αποκαταστάσεων.</li>

        
      </ul>

      <p>
      Η διάρκεια ζωής των εμφυτευμάτων είναι μεγάλη. Πολλές μελέτες αποδεικνύουν την πολύ καλή λειτουργία των εμφυτευμάτων στην 15ετία. 
Επίσης, η λήψη πολύ καλού ιστορικού και η αντιμετώπιση και βελτίωση ορισμένων αντενδείξεων ενισχύουν την μακροβιότητα των εμφυτευματων. Για παράδειγμα, σε περίπτωση καπνίσματος συνιστάται η κατά το δυνατόν μείωσή του. Σε περίπτωση ύπαρξης συστηματικών νόσων π.χ. σακχαρώδη διαβήτη, ενδείκνυται η συστηματική παρακολούθηση και διατήρηση ικανοποιητικών επιπέδων ζαχάρου.
Από τα πιο σημαντικά σημεία είναι η επανεξέταση (follow up) για τη συνεχή παρακολούθηση και τακτικό καθαρισμό των εμφυτευμάτων με χρήση ειδικών ξέστρων. Στο ιατρείο μας γίνεται εφαρμογή συστήματος εξαμηνιαίας παρακολούθησης στοματικής υγείας του ασθενούς και ειδοποίηση μέσω μηνύματος.
Η ηλικία του ασθενούς. Προτιμάται η τοποθέτηση εμφυτευμάτων σε νεαρούς ασθενείς μετά την πλήρη ανάπτυξη τους (κατά προσέγγιση 21 ετών).

        
      </p>

<p>
Ποιοι παράγοντες επηρεάζουν την επιτυχή τοποθέτηση των εμφυτευμάτων;

</p>


<ul>
        <li>Ασθενείς με χαμηλό ανοσοποιητικό σύστημα.</li>
        <li>Αρρύθμιστους διαβητικούς η με γλυκοζηλιωμένη αιμοσφαιρίνη πάνω από 7%.</li>
        <li>    
        <li>Βαρείς καπνιστές.</li>
        <li>Ασθενείς που πέρασαν πρόσφατα κάποιο καρδιακό επεισόδιο.</li>
        <li>Άτομα που τρίζουν τα δόντια τους.</li>
        <li>Άτομα που έχουν υποβληθεί πρόσφατα σε θεραπείες με ακτινοβολίες και χημειοθεραπεία.</li>
        <li>Ασθενείς που έχουν λάβει διάφορες αγωγές για οστεοπόρωση και άλλες ενδείξεις. Σημασία έχει ο τρόπος χορήγησης των διφωσφορικών (π.χ. από του στόματος είναι ηπιότερη) και το μεσοδιάστημα που μεσολαβεί από την τελευταία λήψη του διφωσφορικού.</li>
        <li>Περιοδοντίτιδα.</li>
        </li>
        
      </ul>
     
<p>
Πολύ σημαντική η προετοιμασία της στοματικής κοιλότητας για την τοποθέτηση των εμφυτευμάτων. Αν για παράδειγμα υπάρχει ένδειξη περιοδοντίτιδας θα πρέπει να προηγηθεί συστηματική θεραπεία της περιοδοντίτιδας.
Η επιτυχής διατήρησης των εμφυτευματων επηρεάζεται σημαντικά από τη σωστή και αποτελεσματική στοματική υγιεινή και τη χρήση μεσοδοντίων βουρτσακίων. Καταλυτικό ρόλο παίζει και ο επανέλεγχος που γίνεται τόσο με ψηφιακές ακτινογραφίες κάθε δύο χρόνια, όσο και με καθαρισμό των εμφυτευμάτων. Αντίθετα, σε αποτυχία µπορεί να οδηγήσουν παράγοντες όπως: το κάπνισμα, η περιεμφυτευματίτιδα ή ο βρυγμός (σφίξιμο δοντιών).

    
</p>

<p>
Τα εμφυτεύματα είναι κατάλληλα για όλους τους ενήλικες. Οι μόνοι παράγοντες που θα πρέπει κανείς να λαμβάνει υπόψη, είναι η ανάγκη για καλή γενική υγεία και η επιθυμία για βελτιωμένη ποιότητα ζωής.
Στην περίπτωση που κάποιος έχει χάσει όλα του τα δόντια είναι δυνατό να αντικαταστήσει όλα τα κάτω δόντια µε µια ολική τεχνητή οδοντοστοιχία, που στηρίζεται σε δύο εμφυτεύματα και με μια ακίνητη γέφυρα που στηρίζεται σε τουλάχιστον τέσσερα εμφυτεύματα. Για την άνω γνάθο χρειαζόμαστε τέσσερα εμφυτεύματα για κινητή οδοντοστοιχία και τουλάχιστον έξι για ακίνητη αποκατάσταση.



</p>

<p>
Η αποκατάσταση των δοντιών γίνεται συνήθως αφού παρέλθουν 3 μήνες από την τοποθέτηση των εμφυτευμάτων. Σε συγκεκριμένες περιπτώσεις ασθενών είναι δυνατή η αποκατάσταση των δοντιών µε οδοντικά εμφυτεύματα και ακίνητη προσθετική (γέφυρες) προσωρινές εντός ολίγων ωρών. Προκειμένου να εφαρμοστεί, προηγείται ενδελεχής ακτινολογικός και κλινικός έλεγχος, καθώς υπάρχουν συγκεκριμένες ενδείξεις. Την ημέρα της επέμβασης ή την επόμενη, τοποθετούνται τα εμφυτεύματα και, επιτόπου πάνω σε αυτά, η μεταβατική προσθετική εργασία.
Λόγω μεγάλης ζήτησης, τα εμφυτεύματα έχουν γίνει πλέον πιο προσιτά από οικονομικής πλευράς. Το κόστος της θεραπείας ποικίλλει και εξαρτάται από πολλούς παράγοντες, όπως από τον τύπο και τον αριθμό των εμφυτευμάτων, το είδος της προσθετικής εργασίας που θα τοποθετηθεί και από το αν θα χρειαστεί ή όχι οστικό ή ουλικό μόσχευμα.


</p>



      <Services />
      <Contact />
    </div>
  );
};

export default TeethOdontikaEmfitefmataComponent;
