import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethKatagma from "../Pages/images/services_images/katagma1.png"; // Import the image
import TeethKatagma2 from "../Pages/images/services_images/katagma2.png"; // Import the image
import TeethKatagma3 from "../Pages/images/services_images/katagma3.png"; // Import the image
import Contact from "../Pages/Contact";
import Services from "../Pages/Services";


const TeethKatagmaComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>KΑΤΑΓΜΑΤΑ</h2>
      {/* Main Image */}
      <p>
      Τα κατάγματα διαχωρίζονται ως εξής: 
      </p>
      <p>
      Α. Μικρά κατάγματα του δοντιού χωρίς να προσβάλλεται ο πολφός (νεύρο)
Σε αυτές τις περιπτώσεις μπορεί να γίνει επανατοποθέτηση του σπασμένου τμήματος ή να γίνει αποκατάσταση του δοντιού με σύνθετη ρητίνη. Εάν το σπασμένο κομμάτι είναι διαθέσιμο, διατηρήστε το σε γάλα ή σάλιο η όρο και επισκεφτείτε άμεσα τον Οδοντίατρο σας για να γίνει επανασυγκόλλησή του εάν είναι εφικτό.

      </p>
      <img src={TeethKatagma} alt="Κάταγμα"   className="specific-image"/>
      
      <p>
      Β. Μεγαλύτερα κατάγματα της μύλης του δοντιού στα οποία προσβάλλεται ο πολφός (νεύρο)
Παρατηρείται αιμορραγία από τον πολφό. Αυτοί οι τραυματισμοί απαιτούν ιδιαίτερη προσοχή. Αν η αναπνοή από το στόμα ή η κατανάλωση κρύων υγρών είναι επώδυνη προτιμήστε να δαγκώσετε μια καθαρή υγρή γάζα ή πανί για ανακούφιση μέχρι να φτάσετε σε κάποιο οδοντιατρείο ή νοσοκομείο. Αποφύγετε τη χρήση τοπικών φαρμάκων (ασπιρίνη) ή αλοιφών στις τραυματισμένες περιοχές για να εξαλείψετε τα συμπτώματα του πόνου.
Αναλόγως την έκταση του κατάγματος και τον χρόνο που μεσολαβεί μέχρι την προσέλευση στο οδοντιατρείο, καθορίζεται και η θεραπευτική αντιμετώπιση. Η θεραπεία στις περιπτώσεις αυτές στοχεύει στη διατήρηση της ζωτικότητας του πολφού (νεύρου) εάν αυτό είναι. Εάν αυτό δεν είναι εφικτό τότε πραγματοποιείται συντηρητική ενδοδοντική θεραπεία και αποκατάσταση του δοντιού.

      </p>
      
      {/* Additional Images */}
      <div className="teeth-whitening-container">
        <img src={TeethKatagma2} alt="Κάταγμα2"  className="specific-image" />

<p>

Γ. Κατάγματα ριζών
Τα κατάγματα αυτής της κατηγορίας είναι συνήθως και τα πιο ύπουλα, καθώς μπορεί να μην παρουσιάσουν κανένα σύμπτωμα ή εκδηλώνονται με μια ελαφρά κινητικότητα του τραυματισμένου δοντιού. Ωστόσο, μια λεπτομερής ακτινογραφική εξέταση κρίνεται απαραίτητη προκειμένου να γίνει έγκαιρη διάγνωση και να σχεδιαστεί το κατάλληλο πλάνο  θεραπείας. Η θέση του κατάγματος μπορεί να καθορίσει μακροπρόθεσμα την πρόγνωση του δοντιού. Όσο πιο κοντά είναι το κάταγμα στην άκρη της ρίζας τόσο καλύτερες είναι οι πιθανότητες επιτυχίας και μακροπρόθεσμης διατήρησης του δοντιού. Τα κατάγματα που εντοπίζονται πιο κοντά στη γραμμή των ούλων εξασθενούν περισσότερο το δόντι και οι πιθανότητες επιτυχίας ή διατήρησης του δοντιού μειώνονται. Η σωστή επανατοποθέτηση του δοντιού στην αρχική του θέση και η ακινητοποίηση με νάρθηκα για λίγες εβδομάδες είναι απαραίτητη για μια καλή πρόγνωση. Το δόντι μπορεί να χρειαστεί ενδοδοντική θεραπεία στο μέλλον ή μικροχειρουργική προσέγγιση προκειμένου να σωθεί.

</p>


        <img src={TeethKatagma3} alt="Κάταγμα3"  className="specific-image" />
      </div>
      {/* Additional Text */}
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethKatagmaComponent;
