import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethEnilikas1 from "../Pages/images/services_images/orthoenlikes1.png"; // Import the image
import TeethEnilikas2 from "../Pages/images/services_images/orthoenilikes2.png"; // Import the image
import Services from "../Pages/Services";


import Contact from "../Pages/Contact";


const TeethOrthoEnilikesComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΟΡΘΟΔΟΝΤΙΚΗ ΘΕΡΑΠΕΙΑ ΓΙΑ ΕΝΗΛΙΚΕΣ </h2>
      {/* Main Image */}
      <div className='additional-images2'>
      <img src={TeethEnilikas1 } alt="Ορθοδοντική Ενήλικας1"   className="specific-image"/>
      <img src={TeethEnilikas2} alt="Ορθοδοντική Ενήλικας2"   className="specific-image"/>
      </div>
      <p>
      Ένα μεγάλο ποσοστό ενηλίκων παραδέχεται ότι αισθάνεται μειονεκτικά ή ντρέπεται να χαμογελάσει επειδή τα δόντια του είναι ιδιαίτερα στραβά. Νιώθουν ότι έχουν χάσει την ευκαιρία να ισιώσουν τα δόντια τους και να αποκτήσουν τελικά το τέλειο χαμόγελο , γιατί πλέον είναι ενήλικες.
Εαν και έχουν ήδη εφευρεθεί τα διάφανα brackets οι περισσότεροι ενήλικες δεν αποφασίζουν να τα τοποθετήσουν γιατί αφενός θεωρούν ότι παρόλο το διάφανο υλικό κατασκευής τους συνεχίζουν να είναι ευδιάκριτα (με τη πάροδο του χρόνου ελαφρώς κιτρινίζουν και επίσης συγκρατούν αρκετή ποσότητα τροφής ) και αφετέρου γιατί πιστεύουν ότι δεν γίνεται να διορθωθούν τα δόντια λόγω ηλικίας.



      </p>

      <p>

      Ο βρυγμός έχει πολυπαραγοντική αιτιολογία. 
Επειδή ο βρυγμός συμβαίνει συχνά κατά τη διάρκεια της νύχτας, έχει γίνει εκτενής μελέτη για την φυσιολογία του ύπνου. Οι πρόσφατες έρευνες δειχνουν ότι ο βρυγμός σχετίζεται με διαταραχές του ύπνου καθώς και με δυσκολίες αναπνοής κατά τη διάρκεια του ύπνου με πιο χαρακτηριστικό παράδειγμα την Αποφρακτική Άπνοια Ύπνου. Επιπλέον, υπάρχουν ισχυρές ενδείξεις ότι ο βρυγμός σχετίζεται με διαταραχές του κεντρικού νευρικού συστήματος.  
Πολλοί ψυχοκοινωνικοί παραγόντες που αφορούν τον τρόπο ζωής μας φαίνεται να αυξάνουν την πιθανότητα εμφάνισης βρυγμού. Άτομα με διαταραχές άγχους ή απλώς αγχώδη και έντονο τρόπο ζωής φαίνεται να διατρέχουν υψηλότερο κίνδυνο να παρουσιάσουν βρυγμό. Το στρες φαίνεται να προκαλεί κυρίως βρυγμό κατά τη διάρκεια της ημέρας και όχι νυχτερινό βρυγμό. Σε κατηγορία υψηλού κινδύνου ανήκουν ακόμα άτομα με κατάθλιψη, καπνιστές ή άτομα που κάνουν κατάχρηση ουσιών.


        
      </p>

      <p>
      Πλέον αυτό το πρόβλημα τείνει να εξαλειφθεί με τα “διάφανα μασελάκια“! Η θεραπεία με τα διάφανα μασελάκια περιλαμβάνει ένα συγκεκριμένο αριθμό από διάφανους εξατομικευμένους νάρθηκες, ο καθένας εκ των οποίων σχεδιάζεται απο τον οδοντιατρο και χρησιμοποιείται για περίπου 2 εβδομάδες. Τα μασελάκια ειναι αόρατα και δεν εμποδίζουν καθόλου στην ομιλία, εκτός από τις 2-3 πρώτες μέρες. Ο ασθενής θα πρέπει να τα φοράει καθ´ολη την διάρκεια της ημέρας και να τα αποχωρίζεται μόνο την ώρα του φαγητού .
Η χρονική διάρκεια της ορθοδοντικής θεραπεία εξαρτάται από το εκάστοτε ορθοδοντικό πρόβλημα . Αξίζει να σημειωθεί ότι είτε έντονες σκελετικές ανωμαλίες είτε δύσκολα ορθοδοντικά προβλήματα ίσως να μην μπορούν να διορθωθούν με αυτή τη μέθοδο .

Οι ”αόρατοι” νάρθηκες κατασκευάζονται από ειδικό θερμοπλαστικό υλικό. Αγκαλιάζουν όλη την επιφάνεια των δοντιών. Δίνουν τη δυνατότητα στον ασθενή να αφαιρεί τους μηχανισμούς μόνος του, ώστε να καταναλώσει την τροφή ή για κοινωνικούς λόγους. Απαιτούν την απόλυτη συνεργασία του ασθενούς, ώστε να εφαρμόζεται επαρκείς ώρες. 




      </p>

      <p>
      Σε ποια ηλικία μπορεί ένα παιδί να ξεκινήσει την ορθοδοντική του θεραπεία;

      </p>

      <p>
      Δυστυχώς δεν υπάρχει μία σταθερή και συγκεκριμένη ηλικία που πρέπει να ξεκινήσει μία ορθοδοντική θεραπεία. Καλό είναι να υπάρχει μία 1η επίσκεψη γύρω στην ηλικία των 7 ετών και εκεί να προγραμματίζεται εάν και σε ποια ηλικία θα χρειαστεί η παρέμβαση του ορθοδοντικού. Σε περίπτωση που χάσουμε την ηλικία των 7 για την 1η γνωριμία, θα πρέπει να προγραμματίσουμε κάποιο ραντεβού οπωσδήποτε μέχρι την ηλικία των 9 ετών.


      </p>

    
    
     <Services/>
      <Contact />
      
    </div>
  );
};

export default  TeethOrthoEnilikesComponent;
