import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import Navbar from './Pages/Navbar';
import Home from './Pages/Home';
import About from './Pages/About';
import Appointment from './Pages/Appointment';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Login from './Pages/Login';
import Register from './Pages/Register';
import ChatApp from './Pages/ChatApp';
import BookingHours from './Pages/BookingHours';
import Profile from './Pages/Profile';
import UserProfile from './Pages/UserProfile';
import ApBooking from './Components/ApBooking';
import Dashboard from './Pages/PagesData/Admin/Dashboard';
import ServiceRiza from '../src/Services/ServiceRiza'; // Import the ServiceRiza component
import TeethWhiteningComponent from './Services/Lefkansi'; // Import the TeethWhiteningComponent
import TeethRitiniComponent  from './Services/Ritini';
import TeethOpsisRitiniComponent from './Services/OpsisRitinis';
import TeethOpsiPorselanisComponent from './Services/OpsisPorselanis';
import TeethKeramikesComponent from './Services/Keramikes';
import TeethMetaloKeraComponent from './Services/Metalokeramikes';
import TeethTheOulitidasComponent from './Services/TherapiaOulitidas';
import TeethMiXeiroyrgikiComponent from './Services/MixeirouyrgikiPeriodontiki';
import TeethEpanelegxosComponent from './Services/Epanelegxos';
import TeethEksagogiDontiouComponent from './Services/EksagogiDontiou';
import TeethEksagogiFronimitiComponent from './Services/EksagogiFronimiti';
import TeethOdontikaEmfitefmataComponent from './Services/OdontikaEfmitemata';
import TeethPaidoProlipsiComponent from './Services/PaidoProlipsi';
import TeethEndodontikiComponent from './Services/Endodontiki';
import TeethFthoriosiComponent from './Services/Fthoriosi';
import TeethTherapiaPedikwnComponent from './Services/TherapeiaPaidikwn';
import TeethMitrikosThilasmosComponent from './Services/MitrikosThilasmos';
import TeethVrigmosComponent from './Services/Vrigmos';
import TeethAthlitikosNarthikasComponent from './Services/NarthikesAthlitiki';
import TeethOrthoEnilikesComponent from './Services/Orthodontikienilikes';
import ServicesPeriodontologiaComponent from './Services/ServicesPeriodontologia';
import ServicesEksaktikiComponent from './Services/ServicesEksaktiki';
import ServicesEmfitevmataComponent from './Services/ServicesEmfitevmata';
import ServicesPaidodontiaComponent from './Services/ServicesPaidodontia';
import ServicesOdontikoTravmaComponent from './Services/ServicesOdontikotravma';
import ServicesNarthikesComponent from './Services/ServicesNarthikes';
import ServicesProlipsiComponent from './Services/ServicesProlipsi';
import ServicesKinitikiComponent from './Services/ServicesKinitiki';
import Iatrio from './Pages/Iatrio';
import TeethEmfraksisNeogilonComponent from './Services/EmfraksisNeogilon';
import TeethMikroRogmesComponent from './Services/Mikrorogmes';
import TeethKatagmaComponent from './Services/Katagmata';
import TeethEkgomfonsiComponent from './Services/Ekgomfonsi';
import TeethMerikiOdontostixiaComponent from './Services/MerikiOdontostixia';
import TeethOlikiOdontostixiaComponent from './Services/OlikiOdontostixia';
import TeethKatharismosComponent from './Services/Katharismos';

const App = () => {
  return (
    <>
      <Navbar />

      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <Home />
              <ApBooking />
              <About />
              <Services />
              <Contact />
            </>
          }
        />
        <Route
          exact
          path="/dental-clinic/appointment"
          element={<Appointment />}
        />
        {/* Add new route for ServiceRiza */}
        <Route
          exact
          path="/dental-clinic/service-riza"
          element={<ServiceRiza />}
        />

 {/* Add new route for TeethWhiteningComponent */}
 <Route
          exact
          path="/dental-clinic/teeth-whitening"
          element={<TeethWhiteningComponent />}
        />

         {/* Add new route for TeethRitiniComponent */}
 <Route
          exact
          path="/dental-clinic/teeth-ritini"
          element={<TeethRitiniComponent />}
        />

              {/* Add new route for TeethRitiniComponent */}
 <Route
          exact
          path="/dental-clinic/teeth-opsisritinis"
          element={<TeethOpsisRitiniComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-opsisporselanis"
          element={<TeethOpsiPorselanisComponent/>}
        />


<Route
          exact
          path="/dental-clinic/teeth-keramikes"
          element={<TeethKeramikesComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-metalokeramikes"
          element={<TeethMetaloKeraComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-metalokeramikes"
          element={<TeethMetaloKeraComponent/>}
        />


<Route
          exact
          path="/dental-clinic/teeth-theoulitidas"
          element={<TeethTheOulitidasComponent />}
        />


<Route
          exact
          path="/dental-clinic/teeth-mixeirourgiki"
          element={<TeethMiXeiroyrgikiComponent />}
        />

<Route
          exact
          path="/dental-clinic/teeth-epanelegxos"
          element={<TeethEpanelegxosComponent/>}
        />


<Route
          exact
          path="/dental-clinic/teeth-eksagogidontiou"
          element={<TeethEksagogiDontiouComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-eksagogifronimiti"
          element={<TeethEksagogiFronimitiComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-odontikaemfitefmata"
          element={<TeethOdontikaEmfitefmataComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-paidoprolipsi"
          element={<TeethPaidoProlipsiComponent/>}
        />


<Route
          exact
          path="/dental-clinic/teeth-endodontikitherapeia"
          element={<TeethEndodontikiComponent/>}
        />



<Route
          exact
          path="/dental-clinic/teeth-fthoriosi"
          element={<TeethFthoriosiComponent/>}
        />


<Route
          exact
          path="/dental-clinic/teeth-therapiapedikwn"
          element={<TeethTherapiaPedikwnComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-mitrikosthilasmos"
          element={<TeethMitrikosThilasmosComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-vrigmos"
          element={<TeethVrigmosComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-athlitikosnarthikas"
          element={<TeethAthlitikosNarthikasComponent />}
        />


<Route
          exact
          path="/dental-clinic/teeth-orthodontikienilikes"
          element={<TeethOrthoEnilikesComponent/>}
        />



<Route
          exact
          path="/dental-clinic/teeth-servicesperiodontologia"
          element={<ServicesPeriodontologiaComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-serviceseksaktiki"
          element={<ServicesEksaktikiComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-servicesemfitevmata"
          element={<ServicesEmfitevmataComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-servicespaidodontia"
          element={<ServicesPaidodontiaComponent/>}
        />


        <Route
          exact
          path="/dental-clinic/teeth-servicesodontikotravma"
          element={<ServicesOdontikoTravmaComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-servicesnarthikes"
          element={<ServicesNarthikesComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-servicesprolipsi"
          element={<ServicesProlipsiComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-serviceskiniti"
          element={<ServicesKinitikiComponent/>}
        />


<Route
          exact
          path="/dental-clinic/teeth-iatrio"
          element={<Iatrio/>}
        />


<Route
          exact
          path="/dental-clinic/teeth-emfraksisneogilon"
          element={<TeethEmfraksisNeogilonComponent />}
        />


<Route
          exact
          path="/dental-clinic/teeth-mikrorogmes"
          element={<TeethMikroRogmesComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-katagma"
          element={<TeethKatagmaComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-ekgomfosi"
          element={<TeethEkgomfonsiComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-merikiodontostixia"
          element={<TeethMerikiOdontostixiaComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-olikiodontostixia"
          element={<TeethOlikiOdontostixiaComponent/>}
        />

<Route
          exact
          path="/dental-clinic/teeth-katharismos"
          element={<TeethKatharismosComponent/>}
        />



 

        {/* Continue with other existing routes */}
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/dental-clinic/team" element={<Profile />} />
        <Route exact path="/login_user" element={<Login />} />
        <Route
          exact
          path="/dental-clinic/user/profile"
          element={<UserProfile />}
        />
        <Route
          exact
          path="/dental-clinic/user/chat_section"
          element={<ChatApp />}
        />
        <Route exact path="/dental-clinic/slot" element={<BookingHours />} />
        <Route
          exact
          path="/dental-clinic/admin-person"
          element={<Dashboard />}
        />
      </Routes>
    </>
  );
};

export default App;
