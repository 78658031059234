import React from "react";
import styles from "./About.css";
import about_img from "../Pages/images/Profile_images/female_profile_img.png";
import { Link } from "react-router-dom";
import about_long_des_data from "./PagesData/AboutData";




const About = () => {

  const handleButtonClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <>
      <div
        className={styles.example}
        style={{ backgroundColor: "#fff" }}
        id="about-doctors"
      >
        <div className="about_section_container">
          <h2 className="about_title">
            <span className="about_title_logo">
              <i class="fa-solid fa-angles-right"></i>
            </span>
          ΒΙΟΓΡΑΦΙΚΟ
          </h2>
          <div className="about_container">
            <div className="about_infos">
             
              <p className="about_long_descrp">{about_long_des_data.text}</p>
              <div className="about_align_btn">
                <Link
                  to={"/dental-clinic/team"}
                  style={{ textDecoration: "none", color: "#fff" }}
                  onClick={handleButtonClick}
                >
                  <button className="more_info_btn">Περισσότερα</button>
                  
                </Link>
              </div>
            </div>
            <div className="about_image">
              <img src={about_img} alt="about img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
