import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethEksagogiDontiouComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>
ΕΞΑΓΩΓΗ ΔΟΝΤΙΟΥ 
 </h2>
      
      <p>
      Η εξαγωγή δοντιών είναι η έσχατη λύση του οδοντιάτρου, αφού πρώτα έχει εξαντλήσει όλες τις θεραπευτικές δυνατότητες για να σωθεί ένα δόντι. Γίνεται ανώδυνα, με τη χρήση τοπικής αναισθησίας , ενώ λαμβάνεται μέριμνα για την περιποίηση του τραύματος, τη φαρμακευτική αγωγή, μέχρι και την επούλωση του.
Μετεγχειρητικές οδηγίες
Μετά από μια εξαγωγή δοντιού ή μετά από μια στοματική χειρουργική επέμβαση πρέπει να γίνουν τα παρακάτω:

 
      </p>
      
      <ul>
        <li>Την ημέρα της εξαγωγής και ίσως την επομένη πρέπει να ανασταλούν οι όποιες επαγγελματικές οι άλλες δραστηριότητες.</li>
        <li>Να λαμβάνονται παυσίπονα ανά 4ωρο- 8ωρο (ανάλογα το σκεύασμα που σας έχουμε συνταγογραφήσει) για το πρώτο 24ωρο και έπειτα για όσες μέρες συνεχίζεται ο πόνος.</li>
        <li>    
        <li>Για την αποφυγή αιμορραγίας η γάζα που πιθανόν έχει τοποθετηθεί επί του τραύματος, δαγκώνεται σταθερά επί 30 – 45 λεπτά. Σε περίπτωση που η αιμορραγία συνεχίζεται τοποθετείται νέα γάζα για μία ακόμη ώρα.</li>
        <li>Εφόσον υπάρχουν άλλοι λόγοι υγείας ή φλεγμονής λαμβάνεται αντιβίωση, σύμφωνα με τις οδηγίες μας.</li>
        <li>Την ημέρα της επέμβασης η διατροφή πρέπει να αποτελείται από κρύες και ρευστές τροφές (κρέμα, γιαούρτι, γάλα, κρύες σούπες, πορτοκαλάδα κ.α.)</li>
        <li>Το πρώτο 24ωρο η υγιεινή του στόματος πρέπει να γίνεται πολύ προσεκτικά, ώστε η οδοντόβουρτσα να μην έρχεται σε επαφή με την περιοχή της επέμβασης. Μετά το πρώτο 24ωρο γίνονται πλύσεις του στόματος με το αντιμικροβιακό διάλυμα που σας έχουμε συστήσει 2 φορές την ημέρα για 7 ημέρες τουλάχιστον.</li>
        <li>Το πρώτο 24ωρο η υγιεινή του στόματος πρέπει να γίνεται πολύ προσεκτικά, ώστε η οδοντόβουρτσα να μην έρχεται σε επαφή με την περιοχή της επέμβασης. Μετά το πρώτο 24ωρο γίνονται πλύσεις του στόματος με το αντιμικροβιακό διάλυμα που σας έχουμε συστήσει 2 φορές την ημέρα για 7 ημέρες τουλάχιστον.</li>
        <li>Εάν στο τραύμα έχουν τοποθετηθεί ράμματα, αυτά πρέπει να αφαιρεθούν μετά από μια εβδομάδα από την επέμβαση.</li>
        </li>
        
      </ul>

<p>
Για οποιοδήποτε πρόβλημα ή απορία επικοινωνήστε με το ιατρείο μας.
  
</p>


      
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethEksagogiDontiouComponent ;