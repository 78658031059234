import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesNarthikesComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Νάρθηκες</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="/dental-clinic/teeth-vrigmos">Νάρθηκες βρυγμού</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
              Ο βρυγμός (ή βρουξισμός) είναι μια παραλειτουργική (μη φυσιολογική) συνήθεια η οποία χαρακτηρίζεται από το σφίξιμο ή το τρίξιμο των δοντιών
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"><Link to="/dental-clinic/teeth-athlitikosnarthikas">Αθλητικοί νάρθηκες</Link></Card.Title>
              <Card.Text className="card-text">
              Οι περισσότεροι τραυματισμοί στην περιοχή του προσώπου και των δοντιών μπορούν να προληφθούν.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        
       
    
       
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesNarthikesComponent;