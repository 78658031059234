import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethKeramikes from "../Pages/images/services_images/keramikes.png"; // Import the image
import Services from "../Pages/Services";

import Contact from "../Pages/Contact";


const TeethKeramikesComponent= () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΟΛΟΚΕΡΑΜΙΚΕΣ ΣΤΕΦΑΝΕΣ ΚΑΙ ΓΕΦΥΡΕΣ</h2>
      {/* Main Image */}
      <img src={TeethKeramikes} alt="Κεραμικές"   className="specific-image"/>
      <p>
      Στην περίπτωση που έχετε ήδη στεφάνες (θήκες) που δεν μοιάζουν με φυσικά δόντια και τα όρια τους γκριζάρουν και τις νιώθετε ξένες και ογκώδεις στο στόμα σας, σας γνωστοποιούμε οτι:
      </p>
      
     
    {/* Additional Text */}
      <p>
      Υπάρχουν στεφάνες χωρίς μεταλλικό όριο, λευκές εσωτερικά και εξωτερικά, που μιμούνται τα φυσικά σας δόντια με εξαιρετική αντοχή και ανθεκτικότητα, υλικό επιλογής είναι το διπυριτικό λίθιο (E max) ή το ζιρκόνιο (ζιρκονία).

      </p>
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethKeramikesComponent;
