import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesOdontikoTravmaComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Οδοντικό Τράυμα</h2>

<p className='Odontikoparagraph'>
Το οδοντικό τραύμα είναι μία από τις πιο ατυχείς και δυσάρεστες καταστάσεις που μπορεί να βιώσει κανείς κατά τη διάρκεια της ζωής του.
Τα περισσότερα οδοντικά τραύματα συμβαίνουν κατά την παιδική και εφηβική ηλικία, ωστόσο άνθρωποι όλων των ηλικιών μπορεί να υποστούν οδοντικό τραυματισμό. Tο οδοντικό τραύμα μπορεί να έχει εκτός από σωματικές, ψυχολογικές και κοινωνικές επιπτώσεις.
Οι κυριότερες αιτίες πρόκλησης οδοντικού τραύματος είναι οι αθλητικές δραστηριότητες, οι πτώσεις, τα τροχαία ατυχήματα και η σωματική βία. Ανεξαρτήτως, όμως, της αιτίας ο τύπος του τραύματος, η θέση και η σοβαρότητα της βλάβης θα καθορίσουν τη θεραπεία που είναι απαραίτητη για τη διατήρηση και τη βιωσιμότητα του δοντιού στο στόμα του ασθενή. Ωστόσο, σε ορισμένες περιπτώσεις μαζί με το τραυματισμένο δόντι μπορεί να υποστούν απαρατήρητες τραυματικές βλάβες και τα παρακείμενα δόντια. Οι βλάβες αυτές για να διαγνωσθούν προϋποθέτουν ενδελεχή οδοντιατρική εξέταση.
Η πλειοψηφία των οδοντικών τραυματισμών είναι ρωγμές και μικρά σπασίματα της μύλης του δοντιού (το τμήμα του δοντιού που φαίνεται στο στόμα). Λιγότερο συχνά ένα δόντι μπορεί να μετακινηθεί από τη θέση του και σε σπάνιες περιπτώσεις να βγει εντελώς εκτός στόματος.
Ανεξαρτήτως της σοβαρότητας του τραύματος απαιτείται άμεση αξιολόγηση της κατάστασης από έναν Οδοντίατρο, Ενδοδοντολόγο ή Παιδοδοντίατρο σε περιπτώσεις που ο ασθενής είναι παιδί.


</p>

      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="/dental-clinic/teeth-mikrorogmes">Μικρορωγμές</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
              Πρόκειται για cracks που συμβαίνουν στην αδαμαντίνη, στην επιφάνεια δηλαδή των δοντιών
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"><Link to="/dental-clinic/teeth-katagma">Κατάγματα</Link></Card.Title>
              <Card.Text className="card-text">
              Το κάταγμα δοντιού είναι μια κατάσταση όπου το δόντι έχει υποστεί θλάση, θραύση ή κάποια άλλη μορφή καταστροφής της δοντιαίας ουσίας. 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-ekgomfosi">Εκγόμφωση</Link></Card.Title>
              <Card.Text className="card-text">
              Η εκγομφώση ενός δοντιού αναφέρεται στη διαδικασία κατά την οποία ένας δόντι αρχίζει να βγαίνει από το οστικό του κρεβάτι και να φαίνεται στην επιφάνεια των ούλων.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
       
       
       
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesOdontikoTravmaComponent;