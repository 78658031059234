import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesPaidodontiaComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Παιδοδοντία</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="/dental-clinic/teeth-paidoprolipsi">Πρόληψη</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
              Ένα παιδί μπορεί να επισκεφτεί τον οδοντίατρο για ένα έλεγχο ρουτίνας από ηλικία 2 ετών και άνω.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"><Link to="/dental-clinic/teeth-fthoriosi">Φθορίωση</Link></Card.Title>
              <Card.Text className="card-text">
              Η φθορίωση συνίσταται συνήθως κάθε 6 μήνες ανάλογα τον τερηδονικό κίνδυνο του παιδιού και την διατροφή του ειδικότερα.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-therapiapedikwn">Θεραπεία παιδικών δοντιών</Link></Card.Title>
              <Card.Text className="card-text">
              Aφήνοντας ένα νεογιλό δόντι να τερηδονιστεί δίχως οδοντιατρική φροντίδα συμβάλλουμε στην επιβάρυνση της γενικής υγείας του παιδιού 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
       
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-mitrikosthilasmos">Μητρικό γάλα και νεογιλά δόντια</Link></Card.Title>
              <Card.Text className="card-text">
              Η πράξη του θηλασμού με τα νεογιλά δόντια, δεν επηρεάζει τα δόντια. 
              </Card.Text>
            </Card.Body>
            
          </Card>
        </Col>

        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
            <Card.Title className="card-title"><Link to="/dental-clinic/teeth-emfraksisneogilon">Εμφράξεις νεογιλών δοντιών-πολφοτομή </Link></Card.Title>
              <Card.Text className="card-text">
              Τα νεογιλά δόντια φαίνεται ότι τερηδονίζονται συχνά, λόγω ελλειπους στοματικής υγιεινής και συχνής κατανάλωσης ζαχαρωδών ειδών.
              </Card.Text>
            </Card.Body>
            
          </Card>
        </Col>
       
       
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesPaidodontiaComponent;