import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethRitini from "../Pages/images/services_images/emfraksi.png"; // Import the image
import Services from "../Pages/Services";


import Contact from "../Pages/Contact";


const TeethRitiniComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΕΜΦΡΑΞΕΙΣ ΣΥΝΘΕΤΗΣ ΡΗΤΙΝΗΣ</h2>
      {/* Main Image */}
      <img src={TeethRitini} alt="Teeth Ritini"  className="specific-image" />
      <p>
      Στη σύγχρονη εποχή, το υλικό εκλογής για το λεγόμενο σφράγισμα είναι οι σύνθετες ρητίνες, οι οποίες: 
      </p>
      
      <ul>
        <li>έχουν καλύτερη αισθητική απόδοση, με αποτέλεσμα οι εμφράξεις (τα σφραγίσματα) να είναι λευκές και να μην φαίνεται ότι έχει γίνει σφράγισμα!</li>
        <li>δεν απαιτούν αφαίρεση περισσότερης οδοντικής ουσίας σε αντίθεση με το αμάλγαμα, καθώς η συγκόλληση της σύνθετης γίνεται χημικά, ενώ το αμάλγαμα συγκρατείται μηχανικά με αποτέλεσμα να γίνονται κοιλότητες με συγκεκριμένο σχήμα και να αφαιρείται περισσότερη οδοντική ουσία.</li>
        <li>    
        <li>έχουν καλύτερη αισθητική απόδοση, με αποτέλεσμα οι εμφράξεις (τα σφραγίσματα) να είναι λευκές και να μην φαίνεται ότι έχει γίνει σφράγισμα!</li></li>
      </ul>
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethRitiniComponent;
