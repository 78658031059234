import React, { useState, useEffect } from 'react';
import logo_img from './images/logo.png';
import { HashLink } from 'react-router-hash-link';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import { Link } from 'react-router-dom'; // Import Link
import './Navbar.css';

const Navbar = () => {
  const [isActive, setActive] = useState(false);
  const [servicesVisible, setServicesVisible] = useState(false);
  const [epanorthotikiVisible, setEpanorthotikiVisible] = useState(false);
  const [periodontologiaVisible, setPeriodontologiaVisible] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [eksatikiVisible, seteksatikiVisible] = useState(false);
  const [emfitemataVisible, setemfitemataVisible] = useState(false);
  const [paidodontiaVisible, setpaidodontiaVisible] = useState(false);
  const [odotravmaVisible, setodotravmaVisible] = useState(false);
  const [narthikesVisible, setnarthikesVisible] = useState(false);
  
  const [ordnarthikesVisible, setordnarthikesVisible] = useState(false);
  const [KinitikiVisible, setKinitikiVisible] = useState(false);
  const [EndodontiaVisible, setEndodontiaVisible] = useState(false);



  
  


  const handleClick = () => {
    setActive(!isActive); // Toggle the menu only when the toggle button is clicked
    // Don't reset click count here
  };

  const closeMobileMenu = () => {
    console.log('Closing mobile menu');
    setActive(false);
    setClickCount(0); // Reset click count when closing the menu
     window.scrollTo(0, 0); // Scrolls to the top of the page
  };

  const handleServicesClick = () => {
    if (!isActive) {
      setServicesVisible(!servicesVisible);
    }
  };
  
  const closeDropdownMenus = () => {
    setServicesVisible(false);
    setEpanorthotikiVisible(false);
    setPeriodontologiaVisible(false);
    seteksatikiVisible(false);
    setemfitemataVisible(false);
    setpaidodontiaVisible(false);
    setodotravmaVisible(false);
    setnarthikesVisible(false);
    
    setordnarthikesVisible(false);
    setEndodontiaVisible(false);
    setKinitikiVisible(false);
  };

  const handleDropdownClick = () => {
    // Do not handle toggling the menu state here
    setClickCount(prevCount => prevCount + 1); // Increment click count when the text is clicked
    if (clickCount === 1) {
      // If it's the second click, navigate to the hash link
      window.location.href = '#';
      setClickCount(0); // Reset click count after navigation
    }
  };
  const handleBioClick = (e) => {
    e.stopPropagation(); // Prevent event propagation
    window.scrollTo({ top: 0, behavior: 'smooth' });
    closeMobileMenu(); // Close the mobile menu
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (!event.target.closest('.dropdown-menu') && !event.target.closest('.MenuItems')) {
        closeDropdownMenus();
      }
    };
  
    document.addEventListener('click', handleDocumentClick);
  
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);
  
  
  
  return (
    <div className="nav-container">
      <div className="logo">
        <HashLink to="/#home" onClick={closeMobileMenu}>
          <img src={logo_img} alt="Smile4Ever Logo" />
        </HashLink>
        <HashLink to="/#home" className="nav-title" onClick={closeMobileMenu}>
          Smile4Ever
        </HashLink>

 



        
     
 


        
      </div>
      <div className={isActive ? 'active_links' : 'links'}>
        <div className="MenuItems">
          <HashLink to="/#home" onClick={closeMobileMenu}>
            ΑΡΧΙΚΗ
          </HashLink>
        </div>
        <div className="MenuItems">
          <HashLink to="/dental-clinic/team" onClick={handleBioClick}>
            ΒΙΟΓΡΑΦΙΚΟ
          </HashLink>
        </div>

        <div className="MenuItems">
          <HashLink to="/dental-clinic/teeth-iatrio" onClick={handleBioClick}>
            ΙΑΤΡΕΙΟ
          </HashLink>
        </div>
        
        <div
          className="MenuItems"
          onClick={handleServicesClick}
          onMouseEnter={() => setServicesVisible(true)}
          onMouseLeave={() => setServicesVisible(false)}
        >
          <Dropdown show={servicesVisible}>
            <Dropdown.Toggle as="a" variant="link" id="services-dropdown" className="nav-link dropdown-toggle" onClick={handleDropdownClick}>
              ΥΠΗΡΕΣΙΕΣ
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* Nested dropdown for Επανορθωτική */}
              <Dropdown
                show={epanorthotikiVisible}
                onMouseEnter={() => setEpanorthotikiVisible(true)}
                onMouseLeave={() => setEpanorthotikiVisible(false)}
              >
                <Dropdown.Toggle as="a"  variant="link" id="epanorthotiki-dropdown" className="nav-link dropdown-toggle">
                  Επανορθωτική και Αισθητική Οδοντιατρική
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/dental-clinic/teeth-whitening" onClick={closeMobileMenu}>
                    Λεύκανση
                  </Dropdown.Item>
                  <Dropdown.Item as={Link} to="dental-clinic/teeth-ritini" onClick={closeMobileMenu}>
                    Εμφράξεις Σύνθετης Ρητίνης
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="dental-clinic/teeth-opsisritinis" onClick={closeMobileMenu}>
                    Όψεις Σύνθετης Ρητίνης
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="dental-clinic/teeth-opsisporselanis" onClick={closeMobileMenu}>
                    Όψεις Πορσελάνης
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="dental-clinic/teeth-keramikes" onClick={closeMobileMenu}>
                    Ολοκεραμικές Στεφάνες και Γέφυρες
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="dental-clinic/teeth-metalokeramikes" onClick={closeMobileMenu}>
                    Μεταλλοκεραμικές Στεφάνες και Γέφυρες
                  </Dropdown.Item>
                  {/* Add more nested dropdown items for Επανορθωτική as needed */}
                </Dropdown.Menu>
              </Dropdown>
              {/* End of nested dropdown for Επανορθωτική */}
        
              {/* Nested dropdown for Περιοδοντολογία */}
              
    
              <Dropdown
                show={periodontologiaVisible}
                onMouseEnter={() => setPeriodontologiaVisible(true)}
                onMouseLeave={() => setPeriodontologiaVisible(false)}
              >
                
                <Dropdown.Toggle as="a" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle">
                  Περιοδοντολογία
                </Dropdown.Toggle>
                <Dropdown.Menu>
                <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-katharismos" onClick={closeMobileMenu}>
                    Καθαρισμός
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="dental-clinic/teeth-theoulitidas" onClick={closeMobileMenu}>
                    Θεραπεία Oυλίτιδας
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-mixeirourgiki" onClick={closeMobileMenu}>
                    Μη χειρουργική περιοδοντική θεραπεία
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-epanelegxos" onClick={closeMobileMenu}>
                  Εξατομικευμένο Πρόγραμμα Επανελέγχων
                  </Dropdown.Item>
                  
                
                  {/* Add more nested dropdown items for Περιοδοντολογία as needed */}
                </Dropdown.Menu>
              </Dropdown>
              {/* End of nested dropdown for Περιοδοντολογία */}



              <Dropdown
                show={eksatikiVisible}
                onMouseEnter={() => seteksatikiVisible(true)}
                onMouseLeave={() => seteksatikiVisible(false)}
              >
                
                <Dropdown.Toggle as="a" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle">
                  Εξακτική-Χειρουργική Στόματος
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-eksagogidontiou" onClick={closeMobileMenu}>
                    Εξαγωγή δοντιού
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="dental-clinic/teeth-eksagogifronimiti" onClick={closeMobileMenu}>
                  Εξαγωγή φρονιμήτη
                  </Dropdown.Item>
                  
                  
                  {/* Add more nested dropdown items for Περιοδοντολογία as needed */}
                </Dropdown.Menu>
              </Dropdown>


                 {/* copy this part and adjust for yphreasia*/}

              <Dropdown
                show={emfitemataVisible}
                onMouseEnter={() => setemfitemataVisible(true)}
                onMouseLeave={() => setemfitemataVisible(false)}
              >
                
                <Dropdown.Toggle as="a" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle">
                  Εμφυτεύματα
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-odontikaemfitefmata" onClick={closeMobileMenu}>
                  Οδοντικά εμφυτευματα
                  </Dropdown.Item>
                 
                  {/* Add more nested dropdown items for Περιοδοντολογία as needed */}
                </Dropdown.Menu>
              </Dropdown>
               {/* copy this part and adjust for yphreasia */}


 {/* next yphresia */}


                 {/* copy this part and adjust for yphreasia*/}

                 <Dropdown
                show={paidodontiaVisible}
                onMouseEnter={() =>setpaidodontiaVisible(true)}
                onMouseLeave={() =>setpaidodontiaVisible(false)}
              >
                
                <Dropdown.Toggle as="a" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle">
                Παιδοδοντία 
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-paidoprolipsi" onClick={closeMobileMenu}>
                  Πρόληψη
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-fthoriosi" onClick={closeMobileMenu}>
                  Φθορίωση
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-therapiapedikwn" onClick={closeMobileMenu}>
                  Θεραπεια παιδικών δοντιών
                  </Dropdown.Item>
                 
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-mitrikosthilasmos" onClick={closeMobileMenu}>
                  Μητρικό γάλα και νεογιλά δόντια
                  </Dropdown.Item>
                 
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-emfraksisneogilon" onClick={closeMobileMenu}>
                  Εμφράξεις νεογιλών δοντιών-πολφοτομή
                  </Dropdown.Item>

                 
                  {/* Add more nested dropdown items for Περιοδοντολογία as needed */}
                </Dropdown.Menu>
              </Dropdown>
               {/* copy this part and adjust for yphreasia */}

 {/* next yphresia */}


  {/* copy this part and adjust for yphreasia*/}
  
  <Dropdown
                show={odotravmaVisible}
                onMouseEnter={() =>setodotravmaVisible(true)}
                onMouseLeave={() =>setodotravmaVisible(false)}
              >
                
                <Dropdown.Toggle as={Link} to="/dental-clinic/teeth-servicesodontikotravma" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle">
           
                Οδοντικό τραύμα
                
                </Dropdown.Toggle>
                
                <Dropdown.Menu>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-mikrorogmes" onClick={closeMobileMenu}>
                  Μικρορωγμές
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-katagma" onClick={closeMobileMenu}>
                  Κατάγματα
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-ekgomfosi" onClick={closeMobileMenu}>
                  Εκγόμφωση
                  </Dropdown.Item>
                 
                 
                  {/* Add more nested dropdown items for Περιοδοντολογία as needed */}
                </Dropdown.Menu>
              </Dropdown>
               {/* copy this part and adjust for yphreasia */}
        

 {/* next yphresia */}

 <Dropdown
                show={narthikesVisible}
                onMouseEnter={() =>setnarthikesVisible(true)}
                onMouseLeave={() =>setnarthikesVisible(false)}
              >
                
                <Dropdown.Toggle as="a" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle">
                Νάρθηκες
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-vrigmos" onClick={closeMobileMenu}>
                  Νάρθηκες βρυγμού
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-athlitikosnarthikas" onClick={closeMobileMenu}>
                  Αθλητικοί νάρθηκες
                  </Dropdown.Item>
                 
                 
                 
                  {/* Add more nested dropdown items for Περιοδοντολογία as needed */}
                </Dropdown.Menu>
              </Dropdown>
               {/* copy this part and adjust for yphreasia */}


{/* next yphresia */}


  {/* copy this part and adjust for yphreasia */}





        {/* next yphresia */}       



        <Dropdown
                show={KinitikiVisible}
                onMouseEnter={() =>setKinitikiVisible(true)}
                onMouseLeave={() =>setKinitikiVisible(false)}
              >
                
                <Dropdown.Toggle as="a" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle">
                Κινητή προσθετική 
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-merikiodontostixia" onClick={closeMobileMenu}>
                  Mερικές οδοντοστοιχίες
                  </Dropdown.Item>
                  <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-olikiodontostixia" onClick={closeMobileMenu}>
                  Oλικές οδοντοστοιχίες
                  </Dropdown.Item>
                 
                 
                 
                 
                 
                  {/* Add more nested dropdown items for Περιοδοντολογία as needed */}
                </Dropdown.Menu>
              </Dropdown>


        
<Dropdown
                show={ordnarthikesVisible}
             
                style={{ paddingRight: '0' }}
                className="custom-dropdown"
              >
                
                <Dropdown.Item as={HashLink} to="/dental-clinic/teeth-orthodontikienilikes" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle" onClick={closeMobileMenu}>
                Ορθοδοντικη με διαφανεις ναρθηκες
                </Dropdown.Item>
                
              </Dropdown>
                 

                 
<Dropdown
                show={EndodontiaVisible}
             
                style={{ paddingRight: '0' }}
                className="custom-dropdown"
              >
                
                <Dropdown.Item  as={HashLink} to="/dental-clinic/teeth-endodontikitherapeia" variant="link" id="periodontologia-dropdown" className="nav-link dropdown-toggle" onClick={closeMobileMenu}>
               Eνδοδοντική  Θεραπεία
                </Dropdown.Item>
                
              </Dropdown>
                 
              





 {/* end of yphresia */}       



            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="MenuItems">
          <HashLink to="/dental-clinic/slot" onClick={handleBioClick}>
            ΕΠΙΚΟΙΝΩΝΙΑ
          </HashLink>
        </div>
      </div>
      <div className="toggle_menu_icons" onClick={handleClick}>
        <i className={isActive ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
    </div>
  );
};

export default Navbar;
