import Endodontia from "./images/services_images/endodontia.png"

const ServiceData = [
  {
    id: 1,
    img_src:
      "https://d3t5ai5vcxyqte.cloudfront.net/media/jtklaazjieqhljbboiyao.svg",
    title: "Επανορθωτική και Αισθητική Οδοντιατρική",
    // description: "Ας σώσουμε τον δόντι",
    url: "/dental-clinic/service-riza" // URL for the first service
  },
  {
    id: 2,
    img_src: "https://d3t5ai5vcxyqte.cloudfront.net/media/xerhpggjxuxmfpsu.svg",
    title: "Περιοδοντολογία",
    // description: "Λάμψη στο χαμόγελο σας!",
    url: "/dental-clinic/teeth-servicesperiodontologia" // URL for the first service
  },
  {
    id: 3,
    img_src:
      "https://d3t5ai5vcxyqte.cloudfront.net/media/pegjpxeakmijzlnklbubfsfun.svg",
    title: "Εξακτική - Στοματική Χειρουργική",
    url: "/dental-clinic/teeth-serviceseksaktiki" 
    // description: "Oδοντικά  εμφυτεύματα για μια ζωή",
  },

  {
    id: 5,
    img_src:
      "  https://d3t5ai5vcxyqte.cloudfront.net/media/xckdqmsbqpdhzlhvwood.svg",
    title: "Εμφυτεύματα",
    url: "/dental-clinic/teeth-servicesemfitevmata" 
    // description: "Βελτιώνουμε το χαμόγελό σας, ένα σφράγισμα κάθε φορά",
  },
  
  {
    id: 7,
    img_src:
      "	https://d3t5ai5vcxyqte.cloudfront.net/media/rkkaxifntuslufcqwxelgzu.svg",
    title: "Παιδοδοντία",

    url:"/dental-clinic/teeth-servicespaidodontia" 
    // description: "Για την οδοντιατρική υγεία του παιδιού σας",
  },


  {
    id: 9,
    img_src:
    "https://d3t5ai5vcxyqte.cloudfront.net/media/jtklaazjieqhljbboiyao.svg",
    title: "Οδοντικό Τραύμα",
    url:"/dental-clinic/teeth-servicesodontikotravma"
   
    // description: "Για την οδοντιατρική υγεία του παιδιού σας",
  },

  {
    id: 10,
    img_src:
    "https://d3t5ai5vcxyqte.cloudfront.net/media/pegjpxeakmijzlnklbubfsfun.svg",
    title: "Νάρθηκες",
    url:"/dental-clinic/teeth-servicesnarthikes"
    
    // description: "Για την οδοντιατρική υγεία του παιδιού σας",
  },

 
  {
    id: 12,
    img_src:
    "https://d3t5ai5vcxyqte.cloudfront.net/media/pegjpxeakmijzlnklbubfsfun.svg",
    title: "Κινητή Προσθετική",
    url:"/dental-clinic/teeth-serviceskiniti"
    
    // description: "Για την οδοντιατρική υγεία του παιδιού σας",
  },

  {
    id: 11,
    img_src:
    "https://d3t5ai5vcxyqte.cloudfront.net/media/ztyabpe.svg",
    title: "Ορθοδοντική με διαφανείς νάρθηκες",
    url:"/dental-clinic/teeth-orthodontikienilikes"
    
    // description: "Για την οδοντιατρική υγεία του παιδιού σας",
  },

  

  {
    id: 4,
    img_src:Endodontia ,
    title: "Eνδοδοντική  Θεραπεία",
    url:"/dental-clinic/teeth-endodontikitherapeia"
    // description: "Γνωρίστε τον σωστό τύπο οδοντοστοιχίας για εσάς",
  },
 
  
  
  
  
 

 
 
  





];

export default ServiceData;
