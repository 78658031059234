import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethOpsisRitiniComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΟΨΕΙΣ ΡΗΤΙΝΗΣ</h2>
      
      <p>
      Ολοένα και περισσότεροι ασθενείς  και οδοντίατροι επιλέγουν τις όψεις ρητίνης όταν θέλουν να αλλάξουν το χρώμα, το σχήμα, το μέγεθος και τη θέση των δοντιών.
Είναι επικαλύψεις μικρού πάχους, που τοποθετούνται στη μπροστινή επιφάνεια των δοντιών και απαιτούν ελάχιστο η και καθόλου τρόχισμα στο φυσικό δόντι.
Οι αισθητικές αποκαταστάσεις σύνθετης ρητίνης μπορούν:
 
      </p>
      
      <ul>
        <li>να κλείσουν το διάστημα μεταξύ 2 ή περισσότερων δοντιών </li>
        <li>να αποκαταστήσουν ένα δόντι που έσπασε</li>
        <li>να δώσουν ένα πιο αρμονικό περίγραμμα και μέγεθος σε ένα νάνο πλάγιο τομέα</li>
        <li>να διορθώσουν σε περιορισμένο βαθμό στρεβλοδοντίες και συνωστισμό (στραβά δόντια)</li>
        <li>να αλλάξουν το χρώμα και τη θέση ομάδας δοντιών (4-6-10 δόντια-κεντρικοί και πλάγιοι τομείς, κυνόδοντες και προγόμφιοι)</li>



      </ul>
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethOpsisRitiniComponent;
