import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethEkgomfosi1 from "../Pages/images/services_images/eksomfonsi1.jpg"; // Import the image
import TeethEkgomfosi2 from "../Pages/images/services_images/ekgomfonsi2.jpg"; // Import the image
import TeethEkgomfosi3 from "../Pages/images/services_images/ekgomfonsi3.jpg"; // Import the image

import Contact from "../Pages/Contact";
import Services from "../Pages/Services";


const TeethEkgomfonsiComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΕΚΓΟΜΦΩΣΗ</h2>
      {/* Main Image */}
      <p>
      1. Εμβύθιση: ύπαρξη κάθετης μετακίνησης του δοντιού προς το εσωτερικό του φατνίου. Το δόντι δείχνει κοντύτερο καθώς ένα τμήμα της μύλης έχει πλέον πιεστεί κάτω από τη γραμμή των ούλων. Σε αυτήν την μορφή τραυματισμού δεν είναι απαραίτητο να εκδηλώνεται αυξημένη κινητικότητα ενώ παρουσιάζει μερική ή ολική καταστροφή των νεύρων και των αγγείων που αιματώνουν το δόντι. Συχνά συνοδεύεται από κάταγμα της ρίζας ή και του οστού της γνάθου λόγω της πίεσης. 
      </p>
      <p>
      Α. Μικρά κατάγματα του δοντιού χωρίς να προσβάλλεται ο πολφός (νεύρο)
Σε αυτές τις περιπτώσεις μπορεί να γίνει επανατοποθέτηση του σπασμένου τμήματος ή να γίνει αποκατάσταση του δοντιού με σύνθετη ρητίνη. Εάν το σπασμένο κομμάτι είναι διαθέσιμο, διατηρήστε το σε γάλα ή σάλιο η όρο και επισκεφτείτε άμεσα τον Οδοντίατρο σας για να γίνει επανασυγκόλλησή του εάν είναι εφικτό.

      </p>
      <img src={TeethEkgomfosi1} alt="Κάταγμα"  className="specific-image" />
      
      <p>
      2. Μερική εκγόμφωση - Πλάγια μετατόπιση: Σε αυτή τη μορφή τραυματισμού, συνήθως μετά από κάθετα χτυπήματα προς τον άξονα του δοντιού, το δόντι παρουσιάζει πλάγια μετατόπιση  και πιθανόν να συνοδεύεται από κάταγμα του φατνιακού οστού από την πλευρά της μετατόπισης της ρίζας. Συνήθως δεν εμφανίζεται αυξημένη κινητικότητα λόγω της ενσφήνωσης της ρίζας στο φατνιακό οστό. Υπάρχει εκτεταμένη βλάβη των περιοδοντικών συνδέσμων, μάτωμα, και συχνά παρουσιάζεται νέκρωση του πολφού. 

      </p>
      
      {/* Additional Images */}
      <div className="teeth-whitening-container2">
        <img src={TeethEkgomfosi2} alt="Κάταγμα2"   className="specific-image"/>

<p>

3. Μερική εκγόμφωση – Κάθετη μετατόπιση: Υπάρχει κάθετη μετακίνηση του δοντιού και μερική εκγόμφωση από το φατνίο, δηλαδή έχει βγεί κατά ένα μέρος από τη θέση του και φαίνεται τμήμα της ρίζας του. Συνοδεύεται από μάτωμα στις παρυφές των ούλων. Το δόντι εμφανίζει έντονη κινητικότητα, δείχνει μακρύτερο από το φυσιολογικό προεξέχοντας από τα άλλα δόντια. Υπάρχει μερική ή ολική καταστροφή των νεύρων και διακοπή της αιμάτωσης του δοντιού. 

</p>


        <img src={TeethEkgomfosi3} alt="Κάταγμα3" />
      </div>
      {/* Additional Text */}

      <p>

      Και στις 3 παραπάνω περιπτώσεις, απαιτείται άμεση αξιολόγηση της βλάβης από οδοντίατρο που θα επαναφέρει το δόντι στη θέση του (αν είναι δυνατόν) και θα το σταθεροποιήσει. Πιθανόν να χρειαστεί και ενδοδοντική θεραπεία (απονεύρωση).

      </p>

      <h2>ΟΛΙΚΗ ΕΚΦΟΜΦΩΣΗ</h2>
      <p>
      Εάν το δόντι που βγήκε είναι νεογιλό δεν ξανατοποθετείται στο στόμα αφού υπάρχει κίνδυνος βλάβης στο σπέρμα του μόνιμου αλλά πρέπει να επισκεφτείτε άμεσα τον οδοντίατρο για να ελέγξει τα παρακείμενα δόντια και για τυχόν υπολείμματα ρίζας. 

      </p>

     <p>
     Εάν το δόντι που βγήκε είναι μόνιμο είναι σημαντικό να κινηθείτε γρήγορα:
        </p>
        <ul>
        <li>Βρίσκουμε το δόντι.</li>
        <li>Ξεπλένουμε το δόντι με κρύο τρεχούμενο νερό μέχρι 10 δευτερόλεπτα , χωρίς να πιάσουμε την ρίζα του.</li>
        <li>Βάζουμε το δόντι ξανά στη θέση του στο στόμα και το κρατάμε σταθερό με γάζα. Αν δεν μπορούμε να το κάνουμε αυτό , το διατηρούμε σε γάλα. Αν στον χώρο που είστε δεν υπάρχει κρύο γάλα μπορεί να διατηρηθεί στο στόμα του ασθενή κάτω από την γλώσσα, εφόσον ο ασθενής δεν είναι παιδί αφού υπάρχει κίνδυνος κατάποσης. Σε αυτήν την περίπτωση μεταφέρεται σε δοχείο με σάλιο του ασθενή.</li>
        <li>Επισκεπτόμαστε αμέσως τον οδοντίατρο μας.</li>

      </ul>

      <h2>ΠΡΟΓΝΩΣΗ ΔΟΝΤΙΩΝ ΠΟΥ ΒΓΑΙΝΟΥΝ ΚΑΙ ΕΠΑΝΑΤΟΠΟΘΕΤΟΥΝΤΑΙ </h2>

      <p>
      Η αντιμετώπιση και η εξέλιξη του εκγομφωμένου δοντιού εξαρτάται από δύο σημαντικούς παράγοντες:

      </p>


      <ul>
        <li>Αν έχει ολοκληρωθεί η διάπλαση του ακρορριζίου και η κατάσταση των κυττάρων του περιοδοντικού συνδέσμου, που εξαρτάται από τον χρόνο παραμονής του δοντιού σε στεγνό περιβάλλον αφού μετά από 60 λεπτά έχουν νεκρωθεί.</li>
        <li>Η ταχεία προσέλευση αν είναι δυνατόν και σε λιγότερα από μια ώρα μπορεί να εξασφαλίσει την διατήρηση του δοντιού στο φραγμό.</li>
       
      </ul>
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethEkgomfonsiComponent;
