import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethTherapiaOulitidas from "../Pages/images/services_images/therapiaoulitidas.png"; // Import the image
import Services from "../Pages/Services";


import Contact from "../Pages/Contact";


const TeethTheOulitidasComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΘΕΡΑΠΕΙΑ ΟΥΛΙΤΙΔΑΣ </h2>
      {/* Main Image */}
      <img src={TeethTherapiaOulitidas } alt="Θεραπεία Ουλίτιδας"   className="specific-image"/>
      <p>
      Η ουλίτιδα είναι η φλεγμονή που αναπτύσσεται στα ούλα εξαιτίας της ανεπαρκούς απομάκρυνσης της οδοντικής πλάκας. Η αλλαγή του χρώματος σε σκούρο ερυθρό, η κακοσμία και η αιμορραγία τόσο στο βούρτσισμα όσο και αυτόματα σε βαρύτερες καταστάσεις είναι τα συνηθέστερα συμπτώματα της ουλίτιδας. Σε περίπτωση που η ουλίτιδα δεν αντιμετωπιστεί, είναι πιθανό να οδηγήσει σε πιο σοβαρές καταστάσεις όπως αποστήματα και περιοδοντίτιδα. Ωστόσο, η ουλίτιδα είναι μια πάθηση εύκολα αντιμετωπίσιμη και πλήρως αναστρέψιμη. Συνήθως, κατά τη διάρκεια της εγκυμοσύνης εμφανίζεται μια μορφή ουλίτιδας, η ουλίτιδα κύησης.
Η θεραπεία της ουλίτιδας πραγματοποιείται με την απομάκρυνση της μικροβιακής πλάκας και της τρυγίας δηλαδή με τον οδοντιατρικό καθαρισμό. Μέσα σε λίγες μέρες η υγεία επανέρχεται πλήρως και τα συμπτώματα υποχωρούν. Προσοχή!!! Παράγοντες όπως το κάπνισμα μπορεί να συντελέσουν στην υπόκρυψη συμπτωμάτων όπως η αιμορραγία καθώς προκαλούν αγγειοσύσπαση και μειώνουν την αιμάτωση των ούλων.


      </p>
    
     <Services/>
      <Contact />
      
    </div>
  );
};

export default TeethTheOulitidasComponent;
