import React from 'react';
import './ServiceDontia.css';
import Contact from "../Pages/Contact";
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

import { Container, Row, Col, Card } from 'react-bootstrap';

const ServicesProlipsiComponent = () => {
  return (
    <div>
    <Container className="services-container">
      <h2 className="mt-5 mb-4">Πρόληψη - Συντήρηση θεραπείας</h2>
      <Row>
        <Col md={4} className="mb-4">
          <Card className="service-card">
            <Card.Body>
              <Card.Title className="card-title"> <Link to="#">Τι είναι πρόληψη</Link> {/* Hash link */}</Card.Title>
              <Card.Text className="card-text">
       Κείμενο
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
       
        
       
    
       
      </Row>
    </Container>

    <Contact /> {/* Contact component */}
    </div>
    
  );

  
};



export default ServicesProlipsiComponent;