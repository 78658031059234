import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethOliki from "../Pages/images/services_images/oliki.png"; // Import the image
import Services from "../Pages/Services";

import Contact from "../Pages/Contact";


const TeethOlikiOdontostixiaComponent= () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΟΛΙΚΗ ΟΔΟΝΤΟΣΤΟΙΧΙΑ</h2>
      <p>
      Η ολική οδοντοστοιχία ή αλλιώς μασέλα (γνωστή σε όλους μας!) είναι επιλογή για τους ασθενείς που έχουν χάσει όλα τα δόντια σε μια γνάθο ή πρόκειται να τα εξάγουν. Αντικαθιστά το σύνολο των δοντιών με επιφάνεια έδρασης τον στοματικό βλεννογόνο και την φατνιακή ακρολοφία.
Οι τεχνητές οδοντοστοιχίες χρησιμοποιούνται επίσης για την κάλυψη ελλειμμάτων της υπερώας ή των γναθών μετά από τη χειρουργική αφαίρεση κακοηθών όγκων. Επίσης, χρησιμοποιούνται και σαν μεταβατική αποκατάσταση κατα τη διάρκεια της οστεοενσωμάτωσης των εμφυτευμάτων σε περιπτώσεις ολικής αποκατάστασης του φραγμού με εμφυτεύματα.


      </p>

      {/* Main Image */}
      <img src={TeethOliki} alt="Ολική οδοντοστοιχία"  className="specific-image" />
     
      
     
    
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethOlikiOdontostixiaComponent;
