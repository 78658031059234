import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import TeethPaidoPro from "../Pages/images/services_images/./paidoprolipsi.png"; // Import the image
import Services from "../Pages/Services";
import Contact from "../Pages/Contact";


const TeethPaidoProlipsiComponent= () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΠΡΟΛΗΨΗ </h2>
      {/* Main Image */}
      <img src={TeethPaidoPro} alt="Teeth Whitening"  className="specific-image" />
      <p>
      Ένα παιδί μπορεί να επισκεφτεί τον οδοντίατρο για ένα έλεγχο ρουτίνας από ηλικία 2 ετών και άνω. Εν συνεχεία είναι ορθό να γίνονται τακτικοί έλεγχοι ανά 6 μήνες προκειμένου το παιδί να εξοικειωθεί με τη διαδικασία , το βούρτσισμα και το χώρου του οδοντιατρείου. Στους τακτικούς ελέγχους σκοπός του κλινικού είναι η προαγωγή της πρόληψης , η παρακολούθηση της ανατολής του δοντιού και η θεραπεία τυχόν βλαβών. Αξίζει να τονιστεί ότι στα παιδικά δόντια λόγω της ανατομίας του η τερηδόνα προχωράει πολύ πιο γρήγορα σε σύγκριση με τα μόνιμα.
Με την πρόληψη προσπαθούμε να επιτύχουμε τη μη εμφάνισης τερηδόνας και ουλίτιδας, αλλά και τη μελέτη του χώρου και της θέσης των δοντιών όταν αυτά ήδη διαμορφώνονται με συντηρητικά-μη επεμβατικά μέσα. Στο ιατρείο μας γίνονται φθοριώσεις , sealants και γίνεται μια πρώτη εκτίμηση για ανάγκη παρακολούθησης του παιδιού από ορθοδοντικό.

      </p>
      

      <p>

      Πιο συγκεκριμένα, δεν πρέπει να ξεχνάμε ότι η πέτρα των δοντιών δημιουργείται και στα παιδικά δόντια και αυτά χρειάζονται καθαρισμό. Είναι σύνηθες το φαινόμενο της αιμορραγίας των ούλων (ουλίτιδας) στα παιδιά, είτε γιατί οι γονείς δεν τους τα βουρτσίζουν είτε γιατί τα ίδια δεν έχουν αναπτύξει την επιδεξιότητα που απαιτείται ώστε να αφαιρούν ικανοποιητικά τα υπολείμματα των τροφών, με αποτέλεσμα τα ούλα τους να αιμορραγούν, τα ίδια να πονάνε και να δημιουργείται έτσι ένας φαύλος κύκλος.
Απαραίτητη επίσης στην αντιμετώπιση της τερηδόνας, εκτός του καθαρισμού, είναι η τοπική εφαρμογή φθορίου, γνωστή ως φθορίωση, που πρέπει να γίνεται κάθε 6 μήνες. Αυτή έχει ως αποτέλεσμα να εμποδίζει την εξέλιξη ακόμα και στις αρχικές τερηδόνες, πριν αυτές προχωρήσουν σε δημιουργία κοιλότητας.



      </p>

      <p>

      Απαραίτητη επίσης στην αντιμετώπιση της τερηδόνας, εκτός του καθαρισμού, είναι η τοπική εφαρμογή φθορίου, γνωστή ως φθορίωση, που πρέπει να γίνεται κάθε 6 μήνες. Αυτή έχει ως αποτέλεσμα να εμποδίζει την εξέλιξη ακόμα και στις αρχικές τερηδόνες, πριν αυτές προχωρήσουν σε δημιουργία κοιλότητας.
Οι προληπτικές επικαλύψεις (sealants) επίσης, που τοποθετούνται εφάπαξ στα πίσω δόντια των παιδιών, στους γομφίους, τα προστατεύουν κατά 99% από την τερηδόνα!Παράλληλα, στις επισκέψεις αυτές, ελέγχεται η διάπλαση των γνάθων του παιδιού, η ύπαρξη ικανού χώρου για τη διαδοχή των μόνιμων δοντιών, δίνονται συμβουλές για τη διακοπή τυχόν στοματικών συνηθειών ή και εφαρμόζεται προληπτική ορθοδοντική για την επίλυση ή μείωση της βαρύτητας του όποιου ορθοδοντικού προβλήματος.


      </p>
     
      
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethPaidoProlipsiComponent;
