import React from "react";
import Contact from "./Contact";

import profile_two_img from "./images/Profile_images/female_profile_img.png";
import "./Profile.css";
const Profile = () => {
  const your_profile_details = [
  
    {
      id: 1,
      photo: profile_two_img,
      name: "Χειρουργός οδοντίατρος",
      occupation: "",



      
      description:
        "Η Παναγιωτάκη Σοφία είναι χειρουργός οδοντίατρος για ενήλικες και παιδιά και διατηρεί ιδιωτικό ιατρείο στη Νέα Φιλαδέλφεια. Αποφοίτησε το 2017 και έπειτα εργάστηκε εθελοντικά στο οδοντιατρείο του Πολεμικού Ναυτικού για 2 έτη ως γενική οδοντίατρος, ενώ παράλληλα συνεργάστηκε για 4 έτη με ιδιωτική κλινική, όπου αναλάμβανε περιστατικά προσθετικής και αισθητικής οδοντιατρικής. Ύστερα η γιατρός διορίστηκε ως Επιμελήτρια Β για 3 έτη στο Γ.Ν. Παίδων η Αγία Σοφία, όπου ασχολήθηκε με τον τομέα της παιδοδοντιατρικης και παιδιά με βεβαρυμένο ιατρικό ιστορικό. Η οδοντίατρος έχει παρακολουθήσει πλήθος σεμιναρίων σχετικά με την αισθητική οδοντιατρική και την προσθετική και είναι κάτοχος διπλώματος του University College London. Το ιατρείο διαθέτει πρόσβαση ΑΜΕΑ και τελευταίας τεχνολογίας οδοντιατρικό εξοπλισμό, καθώς επίσης τηρούνται αυστηρά τα πρωτόκολλα αποστείρωσης.",
    },
  ];
  return (
    <>
      <div className="profile_section_container">
        <h2>
          <span>
            <i className="fa-solid fa-angles-right"></i>
          </span>
       Σοφία Παναγιωτάκη
        </h2>
        <div className="p_info_container">
          {your_profile_details.map((e, index) => (
            <div className="profile_details" key={index}>
              <img src={e.photo} alt="profile_image" id="your_profile_image" />
              <div className="profile_info">
                <h3 className="profile_name">{e.name}</h3>
                <h4 style={{ fontWeight: 'bold' }}>{e.occupation}</h4>

              </div>
              <p className="profile_description">{e.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Contact/>
    </>
  );
};

export default Profile;
