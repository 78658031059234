import React from 'react';
import LowerFooter from '../Components/LowerFooter';
import { HashLink } from 'react-router-hash-link'; 
import './Contact.css';
const Contact = () => {


  const handleButtonClick = () => {
    window.scrollTo(0, 0); 
  };

  const clinic_data = [


    {
      id: 1,
      c_day: 'ΔΕΥΤΕΡΑ',
      c_time: '09:00 π.μ - 9:00 μ.μ.',
    },
    {
      id: 2,
      c_day: 'ΤΡΙΤΗ',
      c_time: '09:00 π.μ - 9:00 μ.μ.',
    },
    {
      id: 3,
      c_day: 'ΤΕΤΑΡΤΗ',
      c_time: '09:00 π.μ - 9:00 μ.μ.',
    },
    {
      id: 4,
      c_day: 'ΠΕΜΠΤΗ',
      c_time: '09:00 π.μ - 9:00 μ.μ.',
    },
    {
      id: 5,
      c_day: 'ΠΑΡΑΣΚΕΥΗ',
      c_time: '09:00 π.μ - 9:00 μ.μ.',
    },
    {
      id: 6,
      c_day: 'ΣΑΒΒΑΤΟ',
      c_time: '09:00 π.μ - 4:00 μ.μ.',
    },
    {
      id: 7,
      c_day: 'ΚΥΡΙΑΚΗ',
      c_time: 'Κλειστά',
    },
  ];
  return (
    <>
      <div className="contact_section_container" id="contact-us">
        <div className="container_container">
          <div className="google_map_location">
            <div className="gmap">
              <iframe
                title="gmap_location"
                class="gmap_iframe"
                width="100%"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3142.5743126126836!2d23.737697675770598!3d38.03370239720272!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14a1a313751531e3%3A0x2f903ecef83b1369!2zU21pbGU0ZXZlciDOoM6xzr3Osc6zzrnPic-EzqzOus63IM6jzr_Phs6vzrE!5e0!3m2!1sel!2sgr!4v1710721239321!5m2!1sel!2sgr"
              ></iframe>
            </div>
          </div>
          <div className="basic_contact_user_form">
            <div className="clinic_time_table">
              <h2 style={{ fontFamily: 'Poppins' }}>
                <span>
                  <i className="fa-solid fa-angles-right"></i>
                </span>
                ΩΡΑΡΙΟ
              </h2>
            </div>
            <hr />
            {clinic_data.map((e, index) => (
              <div className="clinic_timing" key={index}>
                <p className="current_day">{e.c_day}</p>
                <p className="current_day_timing">{e.c_time}</p>
              </div>
            ))}
            <div className="d_and_c">
              <div className="direction_to_clinic">
             
              <HashLink to="/dental-clinic/slot"  onClick={handleButtonClick}>
                  ΦΟΡΜΑ ΕΠΙΚΟΙΝΩΝΙΑΣ
                  </HashLink>
              </div>
              <div className="call_to_clinic">
                <a  href="tel:2102587905">ΤΗΛΕΦΩΝΟ:2102587905</a>
              </div>
              
            </div>
          </div>
        </div>
        <LowerFooter />

        <div className="copyright_footer">
          <p>
            <span>
              <i className="fa-regular fa-copyright"></i>
            </span>
            2024
            <a href="/" id="clinic_name">
              Smile4Ever
            </a>
            All Rights Reserved
          </p>
        </div>
      </div>
    </>
  );
};

export default Contact;
