import React from 'react';
import '../Services/Lefkansi.css'; // Make sure to import your styles
import Services from "../Pages/Services";



import Contact from "../Pages/Contact";


const TeethMiXeiroyrgikiComponent = () => {
  return (
    <div className="teeth-whitening-container">
      <h2>ΜΗ ΧΕΙΡΟΥΡΓΙΚΗ ΠΕΡΙΟΔΟΝΤΙΚΗ ΘΕΡΑΠΕΙΑ</h2>
      
      <p>
      Πραγματοποιείται απομάκρυνση των υπερουλικών αλλά και των υποουλικών εναποθέσεων πλάκας και τρυγίας (πέτρας), με τη χρήση συσκευών υπερήχων και ειδικών εργαλείων υποουλικής προσπέλασης. Η διαδικασία ολοκληρώνεται σε 2-4 επισκέψεις, με βάση την σοβαρότητα της κατάστασης, ενώ απαιτείται τοπική αναισθησία. Η διαδικασία δεν είναι επίπονη και συνήθως η επούλωση είναι ασυμπτωματική
 
      </p>
      
      
     
      <Services/>
      <Contact />
    </div>
  );
};

export default TeethMiXeiroyrgikiComponent;
