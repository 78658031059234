import React from 'react';
import { Link } from 'react-router-dom';

const ServiceButton = ({ url }) => {
  const handleClick = () => {
    // Scroll to the top of the page when the button is clicked
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <Link to={url} style={{ textDecoration: 'none', color: '#fff' }} onClick={handleClick}>
      <div className="more_info_btn">
        Περισσότερα
      </div>
    </Link>
  );
};

export default ServiceButton;
