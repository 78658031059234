import React from "react";
import "./Home.css";
import "font-awesome/css/font-awesome.min.css";
import ImageSlider from "../Components/ImageSlider.jsx"; // Import the ImageSlider component
import { HashLink } from "react-router-hash-link";
import "../Pages/ImageSlider.css";

const Home = () => {
  const handleButtonClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

    const callNumber = () => {
    window.location.href = 'tel:2102587905';
  };

  return (
    <>
      <section id="home">
        <div className="slider_container"> {/* Apply CSS class to center images */}
          <ImageSlider /> {/* Place the ImageSlider component at the top */}
        </div>

        <div className="clinic_container">
          <div className="clinic_info">
            <div className="basic_info" id="info_01">
              <h2>Διεύθυνση</h2>
              <p>Προύσης 15, Νέα Φιλαδέλφεια Αττικής</p>
              <HashLink
                to={"https://maps.app.goo.gl/2uoYGxoHf8BHywFd7"}
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className="transperent_btn">Οδηγίες</div>
                
              </HashLink>
              
            </div>
            <div className="basic_info" id="info_02">
            <a href="tel:2102587905"  style={{ textDecoration: "none", color: "#fff" }}>
  <h2>Τηλέφωνο: 2102587905</h2>

</a>
              <p>Οι τιμές μας φέρνουν το χαμόγελο που πάντα ονειρευόσασταν!</p>
              <HashLink
                
                style={{ textDecoration: "none", color: "#fff" }}
                onClick={callNumber}
              >
                <div className="transperent_btn">Καλέστε μας</div>
              </HashLink>
            </div>
            <div className="basic_info" id="info_03">
              <h2>Επικοινωνία</h2>
              <p>Συμπληρώστε την φόρμα  για ραντεβού</p>
              <HashLink
                to={"/dental-clinic/slot"}
                style={{ textDecoration: "none", color: "#fff" }}
                onClick={handleButtonClick} // Attach the function here
              >
                <div className="transperent_btn" id="tr_btn_01">
               Περισσότερα
                </div>
              </HashLink>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
