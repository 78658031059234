import React, { useState } from "react";
import { useForm } from "react-hook-form";
import useWeb3Forms from "@web3forms/react";
import Contact from "./Contact.jsx";

export default function ContactForm() {
  const { register, reset, handleSubmit } = useForm();

  const [isSuccess, setIsSuccess] = useState(false);
  const [result, setResult] = useState("");

  const accessKey = "3cfde617-80cb-4c5c-8710-42eaf5fe1174";

  const { submit: onSubmit } = useWeb3Forms({
    access_key: accessKey,
    settings: {
      from_name: "Acme Inc",
      subject: "New Contact Message from your Website",
      // ... other settings
    },
    onSuccess: (msg, data) => {
      setIsSuccess(true);
      setResult(msg);
      reset();
    },
    onError: (msg, data) => {
      setIsSuccess(false);
      setResult(msg);
    },
  });

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", paddingTop: "50px", backgroundColor: "#f0f8ff", width: "100%", padding: "20px" }}>
      <h2 style={{ marginBottom: "20px" }}>Φόρμα επικοινωνίας</h2>
      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: "400px", width: "100%", padding: "20px", backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", margin: "0 auto" }}>
        <label style={{ marginBottom: "10px", fontWeight: "bold" }}>Όνομα</label>
        <input
          type="text"
          {...register("name", { required: true })}
          style={{ width: "100%", marginBottom: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}
        />
        <label style={{ marginBottom: "10px", fontWeight: "bold" }}>Email</label>
        <input
          type="email"
          {...register("email", { required: true })}
          style={{ width: "100%", marginBottom: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}
        />
        <label style={{ marginBottom: "10px", fontWeight: "bold" }}>Κινητό</label>
        <input
          type="text"
          {...register("mobile", { required: true })}
          style={{ width: "100%", marginBottom: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}
        />
        <label style={{ marginBottom: "10px", fontWeight: "bold" }}>Μήνυμα</label>
        <textarea
          {...register("message", { required: true })}
          placeholder="Μήνυμα"
          style={{ width: "100%", marginBottom: "20px", padding: "10px", border: "1px solid #ccc", borderRadius: "5px" }}
        ></textarea>
        
        <button type="submit" style={{ width: "100%", marginBottom: "20px", padding: "10px", border: "none", borderRadius: "5px", backgroundColor: "#87CEEB", color: "white", cursor: "pointer" }}>
         Αποστολή
        </button>
      </form>
      {isSuccess && <div style={{ marginTop: "20px", padding: "10px", borderRadius: "5px", textAlign: "center",  }}>{result}</div>}
      {!isSuccess && <div style={{ marginTop: "20px", padding: "10px", borderRadius: "5px", textAlign: "center",  }}>{result}</div>}
      <Contact/>
    </div>
  );
}
