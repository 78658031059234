import React from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import slider_one from "../Pages/images/slide_one.png";
import slider_two from "../Pages/images/slide_two.png";
import slider_three from "../Pages/images/slide_three.png";
import "../Pages/ImageSlider.css"; // Import custom CSS file for additional styles

function ImageSlider() {
  return (
    <div className="image-slider-container">
      <Carousel fade={false} slide={true} interval={3000}>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={slider_one}
            alt="First slide"
          />
          <div className="carousel-caption">
            <h3>Η υγεία των δοντιών σας είναι η προτεραιότητά μας </h3>
            
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={slider_two}
            alt="Second slide"
          />
          <div className="carousel-caption">
            <h3>Φροντίδα που χτίζει χαμόγελα</h3>
            
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100 carousel-image"
            src={slider_three}
            alt="Third slide"
          />
          {/* <div className="carousel-caption">
            <h3>Third Slide</h3>
            <p>This is the third slide description</p>
          </div> */}
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default ImageSlider;
